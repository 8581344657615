import React, { createContext } from "react";
import useCampaignTypeHook from "../../hooks/useCampaignType";

const CampaignTypeContext = createContext();

const CampaignTypeProvider = ({ children }) => {
  const { campaignType, setCampaignType } = useCampaignTypeHook();

  return (
    <CampaignTypeContext.Provider value={{ campaignType, setCampaignType }}>
      {children}
    </CampaignTypeContext.Provider>
  );
};

export { CampaignTypeContext, CampaignTypeProvider };
