import React from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles(theme => ({
  templateFilters: {
    width: '100%',
    marginBottom: '15px',
    padding: '6px 16px',
    gap: '8px',
  },
  searchIcon: {
    color: 'gray',
  },
}));

const TemplateFilters = ({ filters, onFilterChange }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.templateFilters} container alignItems="center">
      <Grid item xs={12} sm={2}>
        <TextField
          placeholder={i18n.t('templates.filters.search')}
          fullWidth
          value={filters.search}
          onChange={e => onFilterChange('search', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TemplateFilters;
