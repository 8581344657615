import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import PaperContainer from "../PaperContainer";
import CampaignSummaryModal from "../CampaignSummaryModal";
import CampaignFileSection from "../CampaignFileSection";

import api, { shurikenApi, smsApi } from "../../services/api";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  templateGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: theme.spacing(2),
    maxHeight: "250px",
    overflowY: "auto",
    padding: theme.spacing(1),
    border: "1px solid #ccc",
    borderRadius: theme.shape.borderRadius,
  },
}));

const MAX_FILE_SIZE = 100;
// Lista de encodings permitidos
const requiredHeaders = ["telefone", "cpf", "email"];
const allowedEncodings = ["utf-8", "iso-8859-1", "ascii"];

const NewEmailCampaign = () => {
  const classes = useStyles();

  const [selectedCostCenter, setSelectedCostCenter] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [campaignTitle, setCampaignTitle] = useState("");
  const [costCenters, setCostCenters] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [costCenterName, setCostCenterName] = useState("");

  const [summaryOpen, setSummaryOpen] = useState(false);
  const [campaignData, setCampaignData] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");

  const [errors, setErrors] = useState({
    campaignTitle: false,
    selectedCostCenter: false,
    selectedTemplate: false,
    selectedFile: false,
  });

  const detectEncoding = (text) => {
    const utf8Pattern = /^[\x00-\x7F\xC2-\xF4][\x80-\xBF]*$/;

    if (text.startsWith("\xFF\xFE\x00\x00")) return "utf-32le";
    if (text.startsWith("\x00\x00\xFE\xFF")) return "utf-32be";
    if (text.startsWith("\xFF\xFE")) return "utf-16le";
    if (text.startsWith("\xFE\xFF")) return "utf-16be";
    if (/^[\x00-\x7F]*$/.test(text)) return "ascii"; // Somente caracteres ASCII

    // Verifica se realmente é UTF-8
    if (utf8Pattern.test(text)) return "utf-8";

    return "desconhecido"; // Se não corresponder a nenhum dos padrões acima
  };

  const handleFileChange = async (event) => {
    const file = event.target?.files?.[0]; // Garante que um arquivo foi selecionado
    if (!file) return;

    setErrors({ ...errors, selectedFile: false });

    const validExtensions = ["csv"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!validExtensions.includes(fileExtension) || file.type !== "text/csv") {
      setSelectedFile(null);
      return;
    }

    const maxSize = MAX_FILE_SIZE * 1024 * 1024;
    if (file.size > maxSize) {
      setSelectedFile(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;

      // **Validação do encoding**
      const encoding = detectEncoding(content);
      if (!allowedEncodings.includes(encoding)) {
        setErrorMessage(
          `Codificação inválida: ${encoding}. Permitidos: ${allowedEncodings.join(
            ", "
          )}`
        );
        setSelectedFile(null);
        return;
      }

      // **Dividindo linhas e verificando separador `;`**
      const lines = content.split("\n").map((line) => line.trim());
      if (!lines[0].includes(";")) {
        setErrorMessage(
          "O arquivo deve ter colunas separadas por ponto e vírgula (;)."
        );
        setSelectedFile(null);
        return;
      }

      // **Verificando cabeçalhos obrigatórios**
      const headers = lines[0].split(";").map((h) => h.trim().toLowerCase());
      const missingHeaders = requiredHeaders.filter(
        (header) => !headers.includes(header)
      );
      if (missingHeaders.length > 0) {
        setErrorMessage(
          `Faltam os seguintes cabeçalhos obrigatórios: ${missingHeaders.join(
            ", "
          )}`
        );
        setSelectedFile(null);
        return;
      }

      // **Armazenando a primeira linha de dados**
      const firstDataRow =
        lines.length > 1 ? lines[1] : "Nenhuma linha disponível";
      setSelectedFile(file);
      setErrorMessage(""); // Limpa mensagens de erro caso o upload seja bem-sucedido
    };

    reader.readAsText(file, "UTF-8");
  };

  const handleDownloadExample = () => {
    const exampleCsv =
      "telefone;cpf;email;var1;var2\n5511999999999;12345678900;email@email.com;Carlos;Adao\n";
    const blob = new Blob([exampleCsv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "exemplo.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchTemplates = async (businessId) => {
    // try {
    //   const result = await shurikenApi.get(
    //     `/api/templates/?BusinessId=${businessId}&active=true`
    //   );
    //   setTemplates(result.data);
    // } catch (err) {
    //   console.error(err);
    // }
  };

  const fetchCostCenters = async () => {
    try {
      const result = await smsApi.get("/sms/cost-center/");
      setCostCenters(result.data);
    } catch (err) {
      console.error(err);
      // TODO: handle error
    }
  };

  const handleTemplateSelection = (id) => {};

  const handleCostCenterChange = (event) => {
    setErrors({ ...errors, selectedCostCenter: !event.target.value });
    const selectedId = event.target.value;

    // Busca o nome correspondente ao ID selecionado
    const selectedCenter = costCenters.find(
      (center) => center.id === selectedId
    );

    // Atualiza o estado com um objeto contendo ID e Nome
    setSelectedCostCenter(selectedCenter.id);
    setCostCenterName(selectedCenter.name);
  };

  const handleCampaignTitleChange = (event) => {
    setErrors({ ...errors, campaignTitle: !event.target.value });
    setCampaignTitle(event.target.value);
  };

  const handleOpenSummary = () => {
    let newErrors = {
      campaignTitle: !campaignTitle,
      selectedCostCenter: !selectedCostCenter,
      selectedFile: !selectedFile,
    };

    setErrors(newErrors);

    // Se algum campo estiver inválido, não abre o modal
    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    setCampaignData({
      campaignTitle,
      selectedFile,
      costCenterName,
    });

    setSummaryOpen(true);
  };

  useEffect(() => {
    fetchCostCenters();
  }, []);

  return (
    <>
      {/* Informações da Campanha */}
      <CampaignFileSection
        campaignTitle={campaignTitle}
        handleCampaignTitleChange={handleCampaignTitleChange}
        selectedCostCenter={selectedCostCenter}
        handleCostCenterChange={handleCostCenterChange}
        costCenters={costCenters}
        errors={errors}
        handleDownloadExample={handleDownloadExample}
        handleFileChange={handleFileChange}
        errorMessage={errorMessage}
        selectedFile={selectedFile}
      />

      <PaperContainer>
        {/* Templates - Ocupa toda a linha abaixo */}
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: "15px" }}>
            Templates
          </Typography>
          <FormGroup className={classes.templateGrid}>
            {templates.map((template) => (
              <FormControlLabel
                key={template.id}
                control={
                  <Checkbox
                    // checked={selectedTemplates.includes(template.id)}
                    onChange={() => handleTemplateSelection(template.id)}
                  />
                }
                label={
                  <div>
                    {template.name} ({template.category})
                    <Tooltip title={<Typography>{template.body}</Typography>}>
                      <InfoIcon
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                }
              />
            ))}
          </FormGroup>
          {errors.selectedTemplate && (
            <Typography color="error">
              O template é obrigatório para a campanha.
            </Typography>
          )}
        </Grid>
      </PaperContainer>
      {/* Botões de Ação */}
      <PaperContainer>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenSummary}
            >
              Criar Campanha
            </Button>
          </Grid>
        </Grid>
      </PaperContainer>
      <CampaignSummaryModal
        open={summaryOpen}
        handleClose={() => setSummaryOpen(false)}
        campaignData={campaignData}
      />
    </>
  );
};

export default NewEmailCampaign;
