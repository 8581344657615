/* eslint-disable no-unused-vars */

import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import CampaignsListFilters from '../../components/CampaignsListFilters';
import WhatsAppCampaignsTable from '../../components/WhatsAppCampaignsTable';
import SmsCampaignsTable from '../../components/SmsCampaignsTable';
import CampaignHeader from '../../components/CampaignHeader';
import { CampaignTypeContext } from '../../context/CampaignTypeContext/CampaignTypeContext';

// Função para obter a data de hoje no formato YYYY-MM-DD
const getTodayDate = () => {
  const today = new Date();

  // Formata corretamente no timezone local para YYYY-MM-DD
  return format(today, 'yyyy-MM-dd', { locale: ptBR });
};

const useStyles = makeStyles({
  contentWrapper: {
    overflow: 'hidden',
  },
});

const CampaignsContent = () => {
  const classes = useStyles();

  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState(getTodayDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [costCenter, setCostCenter] = useState([]);

  const { campaignType, setCampaignType } = useContext(CampaignTypeContext);

  const history = useHistory();

  const handleCampaignTypeChange = event => {
    setCampaignType(event.target.value);
  };

  return (
    <MainContainer contentWrapperClassName={classes.contentWrapper}>
      <MainHeader>
        <CampaignHeader
          title={'Campanhas'}
          campaignType={campaignType}
          handleCampaignTypeChange={handleCampaignTypeChange}
          actionTitle={'Nova Campanha'}
          handleAction={() => history.push('/new-campaign')}
        />
      </MainHeader>

      <CampaignsListFilters
        status={status}
        setStatus={setStatus}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        costCenter={costCenter}
        setCostCenter={setCostCenter}
      />

      {campaignType === 'whatsapp' && (
        <WhatsAppCampaignsTable
          startDate={startDate}
          endDate={endDate}
          status={status}
          costCenter={costCenter}
        />
      )}

      {campaignType === 'sms' && (
        <SmsCampaignsTable
          startDate={startDate}
          endDate={endDate}
          status={status}
          costCenter={costCenter}
        />
      )}
    </MainContainer>
  );
};

const Campaigns = () => {
  return <CampaignsContent />;
};

export default Campaigns;
