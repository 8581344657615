import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
  Checkbox,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PaperContainer from '../../components/PaperContainer';
import CampaignSummaryModal from '../CampaignSummaryModal';

import { smsApi } from '../../services/api';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  templateGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(2),
    maxHeight: '250px',
    overflowY: 'auto',
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
  },
  templateCard: {
    border: '1px solid #ddd',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fafafa',
  },
  templateLabel: {
    padding: theme.spacing(1),
    margin: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  templateContent: {
    fontSize: '0.875rem',
    color: '#555',
    whiteSpace: 'pre-wrap',
  },
}));

const NewSmsCampaign = () => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const [templates, setTemplates] = useState([]);
  const [summaryOpen, setSummaryOpen] = useState(false);

  const fetchTemplates = async () => {
    try {
      const result = await smsApi.get(`/sms/templates/`);
      setTemplates(result.data.results);
    } catch (err) {
      console.error(err);
    }
  };

  const handleTemplateSelection = id => {
    const selectedTemplate = templates.find(t => t.id === id);
    setFieldValue('smsTemplate', selectedTemplate);
  };

  const clearHours = () => {
    setFieldValue('scheduleStartHour', null);
    setFieldValue('scheduleEndHour', null);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleOpenSummary = () => {
    let newErrors = {
      costCenter:
        values.costCenter === null ? 'O centro de custo é obrigatório.' : null,
      campaignTitle:
        values.campaignTitle === ''
          ? 'O título da campanha é obrigatório.'
          : null,
      file: values.file === null ? 'O arquivo é obrigatório.' : null,
      smsTemplate: !values.smsTemplate ? 'O template é obrigatório.' : null,
      scheduleStartHour:
        values.scheduleEndHour && !values.scheduleStartHour
          ? 'A hora de início é obrigatória se a hora de término for preenchida.'
          : null,
      scheduleEndHour:
        values.scheduleStartHour && !values.scheduleEndHour
          ? 'A hora de término é obrigatória se a hora de início for preenchida.'
          : null,
    };

    const matrixVariables = [];

    if (values.smsTemplate && values.file) {
      const variableRegex = /{{(.*?)}}/g;
      const templateVariables = new Set(
        values.smsTemplate.content.match(variableRegex)
      );

      for (const variable of templateVariables) {
        const variableName = variable.replace(/{{|}}/g, '').trim();
        const index = values.headers.indexOf(variableName);
        if (index === -1) {
          newErrors.file = `A variável {{${variableName}}} não foi encontrada no arquivo.`;
          break;
        }
        matrixVariables.push({
          template_variable: variableName,
          file_index: index,
        });
      }
    }

    setFieldValue('errors', newErrors);

    // Se algum campo estiver inválido, não abre o modal
    if (Object.values(newErrors).some(error => error)) {
      return;
    }

    setFieldValue('smsMatrixVariables', matrixVariables);
    setSummaryOpen(true);
  };

  return (
    <>
      <PaperContainer>
        <Grid container spacing={2} alignItems="center">
          {/* Coluna esquerda: Datas */}
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="time"
                  label="Hora Início"
                  value={values.scheduleStartHour || ''}
                  onChange={e =>
                    setFieldValue('scheduleStartHour', e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  error={values.errors.scheduleStartHour}
                  helperText={values.errors.scheduleStartHour}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="time"
                  label="Hora Fim"
                  value={values.scheduleEndHour || ''}
                  onChange={e =>
                    setFieldValue('scheduleEndHour', e.target.value)
                  }
                  InputLabelProps={{ shrink: true }}
                  error={values.errors.scheduleEndHour}
                  helperText={values.errors.scheduleEndHour}
                />
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                }}
                item
                xs={2}
              >
                <Button onClick={clearHours} size="small">
                  Limpar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaperContainer>
      <PaperContainer>
        <Grid container spacing={2} alignItems="center">
          {/* Coluna direita: Checkbox contador de cliques */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.useClickCounter || false}
                  onChange={e =>
                    setFieldValue('useClickCounter', e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Usar contador de cliques"
            />
          </Grid>
        </Grid>
      </PaperContainer>

      <PaperContainer>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: '15px' }}>
            Templates
          </Typography>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={values.smsTemplate?.id || ''}
              onChange={e => handleTemplateSelection(e.target.value)}
              className={classes.templateGrid}
            >
              {templates.map(template => (
                <div key={template.id} className={classes.templateCard}>
                  <FormControlLabel
                    className={classes.templateLabel}
                    value={String(template.id)}
                    control={<Radio color="primary" style={{ padding: 0 }} />}
                    label={
                      <Box>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'bold', lineHeight: 'inherit' }}
                        >
                          {template.title}
                        </Typography>
                        <Typography className={classes.templateContent}>
                          {template.content}
                        </Typography>
                      </Box>
                    }
                  />
                </div>
              ))}
            </RadioGroup>
          </FormControl>

          {values.errors.smsTemplate && (
            <Typography color="error">{values.errors.smsTemplate}</Typography>
          )}
        </Grid>
      </PaperContainer>
      <PaperContainer>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenSummary}
            >
              Criar Campanha
            </Button>
          </Grid>
        </Grid>
      </PaperContainer>
      <CampaignSummaryModal
        open={summaryOpen}
        handleClose={() => setSummaryOpen(false)}
      />
    </>
  );
};

export default NewSmsCampaign;
