import React from "react";
import { SvgIcon } from "@material-ui/core";

const WorkspaceIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M6 13c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4m6-10C9.8 3 8 4.8 8 7s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4m6 10c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4"></path>
  </SvgIcon>
);

export default WorkspaceIcon;
