import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Typography,
  Button,
  Chip,
} from "@material-ui/core";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PauseIcon from "@material-ui/icons/Pause";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

import TableRowSkeleton from "../TableRowSkeleton";

import { shurikenApi } from "../../services/api";
import toastError from "../../errors/toastError";

import { format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";

import io from "socket.io-client";

const columns = [
  "Nome",
  "Status",
  "Total",
  "Inválidos",
  "Expirados",
  "Processados",
  "Com Falha",
  "Resposta",
  "Enviados",
  "Recebidos",
  "%",
  "Ações",
];

// Mapeia os status internos para as traduções
const statusTranslation = {
  READY: "Pronta",
  ANALYZING: "Em Análise",
  CANCELLED: "Cancelada",
  ERROR: "Erro",
  RUNNING: "Em Disparo",
  FINISHED: "Finalizada",
  PAUSED: "Pausada",
  WAITING: "Aguardando",
};

const statusStyles = {
  READY: { color: "#1976d2", fontWeight: "bold" }, // Azul
  ANALYZING: { color: "#f57c00", fontWeight: "bold" }, // Laranja
  ERROR: { color: "#d32f2f", fontWeight: "bold" }, // Vermelho
  RUNNING: { color: "#388e3c", fontWeight: "bold" }, // Verde
  FINISHED: { color: "#757575", fontWeight: "bold" }, // Cinza
  PAUSED: { color: "#388e3c", fontWeight: "bold" }, // Laranja
  WAITING: { color: "#f57c00", fontWeight: "bold" }, // Laranja
};

// Formata datas para exibição no front-end (DD/MM/YYYY HH:mm)
const formatDateTimeToBR = (date) => {
  if (!date) return "";
  return format(new Date(date), "dd/MM/yyyy HH:mm", { locale: ptBR });
};

// Formata a data para consulta no backend (dd/MM/yyyy)
const formatDateForQuery = (date) => {
  if (!date) return "";
  const parsedDate = parse(date, "yyyy-MM-dd", new Date());
  return format(parsedDate, "dd/MM/yyyy");
};

const socket = io("http://localhost:8000");

const WhatsAppCampaignsTable = ({ startDate, endDate, status, costCenter }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchCampaigns = async (page = 1) => {
    setLoading(true);
    try {
      // Monta os query params dinamicamente
      const params = new URLSearchParams();
      params.append("page", page);
      params.append("limit", 20);

      if (startDate) params.append("startDate", formatDateForQuery(startDate));
      if (endDate) params.append("endDate", formatDateForQuery(endDate));
      if (status) params.append("status", status);
      if (costCenter !== undefined && costCenter.id)
        params.append("costCenterId", costCenter.id);

      const { data } = await shurikenApi.get(
        `/api/campaigns?${params.toString()}`
      );
      setCampaigns(data.data);
      setTotalPages(data.countPages);
      setTotalRecords(data.count);
      setCurrentPage(page);
    } catch (error) {
      console.error(error);
      toastError("Erro ao buscar campanhas");
    } finally {
      setLoading(false);
    }
  };

  const handleStartCampaign = async (campaignId) => {
    try {
      const response = await shurikenApi.post(
        `/api/campaigns/${campaignId}/start`
      );
      const updatedCampaign = response.data;
      setCampaigns((prevCampaigns) =>
        prevCampaigns.map((campaign) =>
          campaign.id === updatedCampaign.id ? updatedCampaign : campaign
        )
      );
    } catch (error) {
      console.error(error);
      toastError("Erro ao iniciar campanha");
    }
  };

  const handlePauseCampaign = async (campaignId) => {
    try {
      const response = await shurikenApi.post(
        `/api/campaigns/${campaignId}/pause`
      );
      const updatedCampaign = response.data;
      setCampaigns((prevCampaigns) =>
        prevCampaigns.map((campaign) =>
          campaign.id === updatedCampaign.id ? updatedCampaign : campaign
        )
      );
    } catch (error) {
      console.error(error);
      toastError("Erro ao iniciar campanha");
    }
  };

  useEffect(() => {
    fetchCampaigns(currentPage);
  }, [startDate, endDate, status, costCenter]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId"); // ou req.user.parentId

    socket.emit("join-company", companyId);

    socket.on("campaign-status-updated", (updatedCampaign) => {
      console.log("campaign-status-updated", updatedCampaign);
      setCampaigns((prevCampaigns) =>
        prevCampaigns.map((campaign) =>
          campaign.id === updatedCampaign.id ? updatedCampaign : campaign
        )
      );
    });

    return () => {
      socket.off("campaign-status-updated");
    };
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper
          variant="outlined"
          style={{ padding: "16px", overflowX: "auto" }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell key={index} align="center">
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns.map((campaign) => {
                const isDisabled =
                  (campaign.status !== "READY" &&
                    campaign.countProcessed === 0) ||
                  campaign.status === "ANALYZING";

                return (
                  <TableRow key={campaign.id}>
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        {campaign.name}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {" "}
                        Criado em: {formatDateTimeToBR(campaign.createdAt)}{" "}
                        {campaign.source === "API" ? "API" : "WEB"}
                      </Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography style={statusStyles[campaign.status]}>
                        {statusTranslation[campaign.status] || "Desconhecido"}
                      </Typography>
                      {campaign.accountViolation &&
                        campaign.accountViolation.trim() !== "" && (
                          <Chip
                            label={campaign.accountViolation}
                            size="small"
                            style={{
                              marginTop: 4,
                              backgroundColor: "#d32f2f",
                              color: "#fff",
                              fontSize: "0.65rem",
                              height: 20,
                              padding: "0 6px",
                            }}
                          />
                        )}
                    </TableCell>

                    <TableCell align="center">{campaign.countTotal}</TableCell>
                    <TableCell align="center">
                      {campaign.countInvalid}
                    </TableCell>
                    <TableCell align="center">
                      {campaign.countExpired}
                    </TableCell>
                    <TableCell align="center">
                      {campaign.countProcessed}
                    </TableCell>
                    <TableCell align="center">{campaign.countFailed}</TableCell>
                    <TableCell align="center">
                      {campaign.countReplied}
                    </TableCell>
                    <TableCell align="center">{campaign.countSent}</TableCell>
                    <TableCell align="center">
                      {campaign.countDelivered}
                    </TableCell>

                    <TableCell align="center">
                      {campaign.countTotal === 0 ||
                      campaign.countTotal - campaign.countInvalid === 0
                        ? "0%"
                        : `${(
                            (campaign.countDelivered /
                              (campaign.countTotal - campaign.countInvalid)) *
                            100
                          ).toFixed(2)}%`}
                    </TableCell>

                    <TableCell align="center">
                      {campaign.status === "FINISHED" ? (
                        <CheckCircleIcon color="disabled" />
                      ) : campaign.status === "ERROR" ? (
                        <ErrorOutlineIcon style={{ color: "#d32f2f" }} />
                      ) : campaign.status === "RUNNING" ? (
                        <IconButton
                          style={{ color: "#388e3c" }}
                          onClick={() => handlePauseCampaign(campaign.id)}
                        >
                          <PauseIcon />
                        </IconButton>
                      ) : campaign.status === "WAITING" ? (
                        <IconButton disabled={true} size="small">
                          <HourglassEmptyIcon></HourglassEmptyIcon>
                        </IconButton>
                      ) : (
                        <IconButton
                          disabled={isDisabled}
                          size="small"
                          style={{
                            opacity: isDisabled ? 0.2 : 1,
                            color: isDisabled ? "#f57c00" : "#1976d2",
                          }}
                          onClick={() => handleStartCampaign(campaign.id)}
                        >
                          <PlayArrowIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}

              {loading && <TableRowSkeleton columns={columns.length} />}
            </TableBody>
          </Table>

          {/* PAGINAÇÃO */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <Button
              disabled={currentPage === 1}
              onClick={() => fetchCampaigns(currentPage - 1)}
            >
              Anterior
            </Button>
            <Typography>
              Página {currentPage} de {totalPages}
            </Typography>
            <Button
              disabled={currentPage === totalPages}
              onClick={() => fetchCampaigns(currentPage + 1)}
            >
              Próximo
            </Button>
          </div>
        </Paper>
      )}
    </>
  );
};

export default WhatsAppCampaignsTable;
