import React, { forwardRef } from 'react';
import { Badge, withStyles } from '@material-ui/core';

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: props => {
      switch (props.status) {
        case 'DISABLED':
          return '#f44336';
        case 'ACTIVE':
          return '#4caf50';
        default:
          return '#999';
      }
    },
    color: props => {
      switch (props.status) {
        case 'DISABLED':
          return '#f44336';
        case 'ACTIVE':
          return '#4caf50';
        default:
          return '#999';
      }
    },
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: 10,
    height: 10,
    borderRadius: '50%',
    border: '2px solid white',
  },
}))(Badge);

const StatusBadge = forwardRef(({ status }, ref) => {
  return (
    <StyledBadge
      ref={ref}
      status={status}
      variant="dot"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    />
  );
});

export default StatusBadge;
