import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import WhatsAppTemplatesTable from './WhatsAppTemplatesTable';
import WhatsAppTemplateFilters from './WhatsAppTemplatesFilters';
import WhatsAppViewTemplateModal from './WhatsAppViewTemplateModal';
import { shurikenApi } from '../../../services/api';
import toastError from '../../../errors/toastError';

const initialFilters = {
  search: '',
  BusinessId: '',
  isValid: '',
  TemplateTypeId: '',
};

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: 'scroll',
    ...theme.scrollbarStyles,
  },
}));

const WhatsAppTemplates = forwardRef((_, ref) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  const [filters, setFilters] = useState(initialFilters);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  //TODO: Add pagination to the API
  const fetchTemplates = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const response = await shurikenApi.get('/api/templates/', {
          params: {
            name: filters.search || undefined,
            BusinessId: filters.BusinessId || undefined,
            isValid: filters.isValid || undefined,
            TemplateTypeId: filters.TemplateTypeId || undefined,
            // page: page,
          },
        });
        setTemplates(prevTemplates => {
          const templatesMap = new Map(prevTemplates.map(t => [t.id, t]));
          response.data.forEach(template => {
            templatesMap.set(template.id, template);
          });
          return Array.from(templatesMap.values());
        });
        // setHasMore(!!response.data.next);
        setPage(page);
      } catch (err) {
        console.error(err);
        toastError(err);
      } finally {
        setLoading(false);
      }
    },
    [filters]
  );

  useEffect(() => {
    setTemplates([]);
    setLoading(true);

    const handler = setTimeout(() => {
      fetchTemplates(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [fetchTemplates]);

  const handleFilterChange = (field, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const loadMore = () => {
    fetchTemplates(page + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleViewTemplate = template => {
    setSelectedTemplateId(template.id);
    setIsViewModalOpen(true);
  };

  const handleCloseViewTemplateModal = () => {
    setSelectedTemplateId(null);
    setIsViewModalOpen(false);
  };

  return (
    <Box className={classes.container}>
      <WhatsAppTemplateFilters
        filters={filters}
        onFilterChange={handleFilterChange}
      />

      <WhatsAppViewTemplateModal
        open={isViewModalOpen}
        onClose={handleCloseViewTemplateModal}
        templateId={selectedTemplateId}
      />

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <WhatsAppTemplatesTable
          loading={loading}
          templates={templates}
          onViewTemplate={handleViewTemplate}
        />
      </Paper>
    </Box>
  );
});

export default WhatsAppTemplates;
