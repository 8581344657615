import React from 'react';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import { BusinessesFilter } from '../../BusinessesFilter';
import { TemplateTypeFilter } from '../../TemplateTypeFilter';
import { i18n } from '../../../translate/i18n';

const useStyles = makeStyles(theme => ({
  templateFilters: {
    width: '100%',
    marginBottom: '15px',
    padding: '6px 16px',
    gap: '8px',
  },
  searchIcon: {
    color: 'gray',
  },
  businessesFilter: {
    minWidth: 300,
  },
  templateTypeFilter: {
    minWidth: 180,
  },
  select: {
    minWidth: 120,
    margin: 0,
  },
}));

const WhatsAppTemplateFilters = ({ filters, onFilterChange }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.templateFilters} container alignItems="center">
      <Grid item xs={12} sm={2}>
        <TextField
          placeholder={i18n.t('templates.filters.search')}
          fullWidth
          value={filters.search}
          onChange={e => onFilterChange('search', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <BusinessesFilter
        className={classes.businessesFilter}
        onFiltered={value => onFilterChange('BusinessId', value?.id)}
        multiple={false}
      />
      <FormControl variant="outlined" className={classes.select} margin="dense">
        <InputLabel id="status-selection-label">Status</InputLabel>

        <Select
          label="Status"
          name="status"
          labelId="status-selection-label"
          id="status-selection"
          value={filters.isValid}
          onChange={e => onFilterChange('isValid', e.target.value)}
        >
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="true">Ativo</MenuItem>
          <MenuItem value="false">Inativo</MenuItem>
        </Select>
      </FormControl>
      <TemplateTypeFilter
        className={classes.templateTypeFilter}
        onFiltered={value => onFilterChange('TemplateTypeId', value?.id)}
        multiple={false}
      />
    </Grid>
  );
};

export default WhatsAppTemplateFilters;
