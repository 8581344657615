import React, { useState, useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
    TableBody,
    TableRow,
    TableCell,
    Table,
    TableHead,
    Paper,
    Badge, 
    IconButton, 
    Tooltip, 
    Typography, 
    withStyles,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button
} from "@material-ui/core";
import { Edit, Search, CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

import { AuthContext } from "../../context/Auth/AuthContext";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import WhatsAppModal from "../../components/WhatsAppModal";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import StatusBadge from "../../components/StatusBadge";
import { Can } from "../../components/Can";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { BusinessesFilter } from "../../components/BusinessesFilter";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: "center",
    },
    buttonProgress: {
        color: green[500],
    },
    badge: {
        color: "#FFF",
        padding: 1,
        fontWeight: 'bold',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 3,
        fontSize: "0.9em",
        whiteSpace: "nowrap",
        textTransform: 'uppercase',
        backgroundColor: "#000",
    },
    businessesFilter: {
        minWidth: 300,
    },
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const OPERATING_STATUS_MAP = {
    CONNECTED: '#166534',
    PENDING: '#991b1b',
    DELETED: '#991b1b',
    MIGRATED: '#991b1b',
    BANNED: '#991b1b',
    RESTRICTED: '#991b1b',
    RATE_LIMITED: '#991b1b',
    FLAGGED: '#991b1b',
    DISCONNECTED: '#991b1b',
    UNKNOWN: '#991b1b',
    UNVERIFIED: '#991b1b',
    default: '#999',
  };

const QUALITY_SCORE_MAP = {
    RED: '#991b1b',
    YELLOW: '#854d0e',
    GREEN: '#166534',
    default: '#999',
  };

const ConnectionsMeta = () => {
    const classes = useStyles();

    const [whatsApps, setWhatsApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
    const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);

    const [searchParam, setSearchParam] = useState("");
    const [selectedBusinessId, setSelectedBusinessId] = useState(null);
    const [statusFilter, setStatusFilter] = useState("");

    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState([]);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchWhatsApps(searchParam, selectedBusinessId, statusFilter);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, selectedBusinessId, statusFilter]);

    const fetchWhatsApps = async (searchParam, selectedBusinessId, statusFilter) => {
        try {
            const { data } = await api.get("/officialWhatsapp/", {
                params: {
                    phoneNumber: searchParam,
                    businessId: selectedBusinessId,
                    phoneStatus: statusFilter,
                }
            });
            setWhatsApps(data);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    }

    // const handleOpenWhatsAppModal = () => {
    //     setSelectedWhatsApp(null);
    //     setWhatsAppModalOpen(true);
    // };

    const handleCloseWhatsAppModal = () => {
        setWhatsAppModalOpen(false);
        setSelectedWhatsApp(null);
        fetchWhatsApps(searchParam, selectedBusinessId);
    };

    const handleEditWhatsApp = whatsApp => {
        setSelectedWhatsApp(whatsApp);
        setWhatsAppModalOpen(true);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleSelectedBusinesses = (selectedBusiness) => {
        setSelectedBusinessId(selectedBusiness?.id || null);
    };

    const handleStatusFilter = (event) => {
        setStatusFilter(event.target.value);
    }

    const handleBatchEdit = () => {
        if (selected.length === 0) {
            return;
        }
        setSelectedWhatsApp(null);
        setWhatsAppModalOpen(true);
    }

    useEffect(() => {
        setSelected(selectAll ? whatsApps.map(({ whatsappId }) => whatsappId) : [])
    }, [whatsApps, selectAll])

    return (
        <MainContainer>
            <WhatsAppModal
                open={whatsAppModalOpen}
                onClose={handleCloseWhatsAppModal}
                whatsAppId={selectedWhatsApp?.whatsappId}
                selectedWhatsApps={selected}
                isOfficial
            />
            <MainHeader>
                <Title>Conexões Meta</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("contacts.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <Search style={{ color: "gray" }} />
                            </InputAdornment>
                            ),
                        }}
                    />
                    <BusinessesFilter 
                        className={classes.businessesFilter} 
                        onFiltered={handleSelectedBusinesses} 
                        multiple={false}
                    />
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        margin="dense"
                    >
                        <InputLabel id="status-selection-label">
                            Ativo
                        </InputLabel>

                        <Select
                            label="Status"
                            name="status"
                            labelId="status-selection-label"
                            id="status-selection"
                            value={statusFilter}
                            onChange={handleStatusFilter}
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value="ACTIVE">Ativo</MenuItem>
                            <MenuItem value="DISABLED">Inativo</MenuItem>
                        </Select>
                    </FormControl>
                    <Can
                        role={user.profile}
                        perform="official-connections-page:editConnection"
                        yes={() => (
                            <Button
                                style={{
                                    backgroundColor: '#333',
                                    color: 'white'
                                }}
                                onClick={() => handleBatchEdit()}
                                variant="contained"
                            >
                                Editar
                            </Button>
                        )}
                    />
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Número
                            </TableCell>
                            <TableCell align="center">
                                Tier
                            </TableCell>
                            <TableCell align="center">
                                Ativo
                            </TableCell>
                            <TableCell align="center">
                                BM
                            </TableCell>
                            <TableCell align="center">
                                Status Conta
                            </TableCell>
                            <TableCell align="center">
                                Saúde
                            </TableCell>
                            <TableCell align="center">
                                Fila de Transferência Receptivo
                            </TableCell>
                            <Can
                                role={user.profile}
                                perform="official-connections-page:editConnection"
                                yes={() => (
                                    <TableCell align="center">
                                        {i18n.t("connections.table.actions")}
                                    </TableCell>
                                )}
                            />
                            <Can
                                role={user.profile}
                                perform="official-connections-page:editConnection"
                                yes={() => (
                                    <TableCell
                                    align="center"
                                    onClick={() => {
                                        setSelectAll(selectAll ? false : true)
                                    }}
                                >
                                    {
                                        selectAll
                                        ? <CheckBox />
                                        : <CheckBoxOutlineBlank />
                                    }
                                    </TableCell>
                                )}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <>
                                <TableRowSkeleton columns={8} />
                                <TableRowSkeleton columns={8} />
                                <TableRowSkeleton columns={8} />
                            </>
                        ) : (
                            <>
                                {whatsApps?.length > 0 &&
                                    whatsApps.map(whatsApp => (
                                        <TableRow key={whatsApp.id}>
                                            <TableCell align="center">{whatsApp.phoneNumber}</TableCell>
                                            <TableCell align="center">{whatsApp.messagingLimit}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip 
                                                    title={
                                                        <Typography variant="body2">
                                                            {whatsApp.phoneStatusReason || ''}
                                                        </Typography>
                                                    }
                                                >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "8px" }}>
                                                    <StatusBadge status={whatsApp.phoneStatus} />
                                                </div>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">{whatsApp.businessName}</TableCell>
                                            <TableCell align="center">{whatsApp.accountStatus}</TableCell>
                                            <TableCell align="center">
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                                    {whatsApp.accountViolation && <Badge style={{ backgroundColor: "#991b1b" }} className={classes.badge}>Violação: {whatsApp.violationType}</Badge>}
                                                    <Badge style={{ backgroundColor: OPERATING_STATUS_MAP[whatsApp.operatingStatus] || OPERATING_STATUS_MAP.default }} className={classes.badge}>Status: {whatsApp.operatingStatus}</Badge>
                                                    <Badge style={{ backgroundColor: QUALITY_SCORE_MAP[whatsApp.qualityScore] || QUALITY_SCORE_MAP.default }} className={classes.badge}>Qualidade: {whatsApp.qualityScore}</Badge>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">
                                                {whatsApp.transferQueue && <Badge style={{ backgroundColor: whatsApp.transferQueue.color }} className={classes.badge}>{whatsApp.transferQueue.name}</Badge>}
                                            </TableCell>
                                            <Can
                                                role={user.profile}
                                                perform="official-connections-page:editConnection"
                                                yes={() => (
                                                    <TableCell align="center">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleEditWhatsApp(whatsApp)}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </TableCell>
                                                )}
                                            />
                                            <Can
                                                role={user.profile}
                                                perform="official-connections-page:editConnection"
                                                yes={() => (
                                                    <TableCell align="center">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                setSelected(prevState => {
                                                                const found = prevState.includes(whatsApp.whatsappId);
                                    
                                                                return found
                                                                    ? prevState.filter(id => id !== whatsApp.whatsappId)
                                                                    : [...prevState, whatsApp.whatsappId]
                                                                })
                                                            }}
                                                        >
                                                        {
                                                            selected.includes(whatsApp.whatsappId) ? <CheckBox /> : <CheckBoxOutlineBlank />
                                                        }
                                                        </IconButton>
                                                    </TableCell>
                                                )}
                                            />
                                        </TableRow>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default ConnectionsMeta;
