import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { has, isArray } from "lodash";
import * as Sentry from "@sentry/react";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api, {
  officialApi,
  webApi,
  smsApi,
  emailApi,
  shurikenApi,
} from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import { eraseDatabase } from "../../services/db";
import moment from "moment";

const useAuth = () => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const applyAccessToken = (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
    }
    return config;
  };

  const refreshAccessToken = async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      const { data } = await api.post("/auth/refresh_token");
      if (data) {
        localStorage.setItem("token", JSON.stringify(data.token));
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
        officialApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        webApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        smsApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        emailApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        shurikenApi.defaults.headers.Authorization = `Bearer ${data.token}`;
      }
      return api(originalRequest);
    }
    if (error?.response?.status === 401) {
      setIsAuth(false);
      setUser({});
      Sentry.setUser(null);
      localStorage.clear();
      await eraseDatabase();
      api.defaults.headers.Authorization = undefined;
      officialApi.defaults.headers.Authorization = undefined;
      webApi.defaults.headers.Authorization = undefined;
      smsApi.defaults.headers.Authorization = undefined;
      emailApi.defaults.headers.Authorization = undefined;
      shurikenApi.defaults.headers.Authorization = undefined;
    }
    return Promise.reject(error);
  };

  api.interceptors.request.use(applyAccessToken, (error) => {
    Promise.reject(error);
  });

  officialApi.interceptors.request.use(applyAccessToken, (error) => {
    Promise.reject(error);
  });

  webApi.interceptors.request.use(applyAccessToken, (error) => {
    Promise.reject(error);
  });

  smsApi.interceptors.request.use(applyAccessToken, (error) => {
    Promise.reject(error);
  });
  
  emailApi.interceptors.request.use(applyAccessToken, (error) => {
    Promise.reject(error);
  });

  shurikenApi.interceptors.request.use(applyAccessToken, (error) => {
    Promise.reject(error);
  });

  api.interceptors.response.use((response) => {
    return response;
  }, refreshAccessToken);

  officialApi.interceptors.response.use((response) => {
    return response;
  }, refreshAccessToken);

  webApi.interceptors.response.use((response) => {
    return response;
  }, refreshAccessToken);

  smsApi.interceptors.response.use((response) => {
    return response;
  }, refreshAccessToken);

  emailApi.interceptors.response.use((response) => {
    return response;
  }, refreshAccessToken);

  shurikenApi.interceptors.response.use((response) => {
    return response;
  }, refreshAccessToken);

  useEffect(() => {
    const token = localStorage.getItem("token");
    (async () => {
      if (token) {
        try {
          const { data } = await api.post("/auth/refresh_token");
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          officialApi.defaults.headers.Authorization = `Bearer ${data.token}`;
          webApi.defaults.headers.Authorization = `Bearer ${data.token}`;
          smsApi.defaults.headers.Authorization = `Bearer ${data.token}`;
          emailApi.defaults.headers.Authorization = `Bearer ${data.token}`;
          shurikenApi.defaults.headers.Authorization = `Bearer ${data.token}`;
          setIsAuth(true);
          setUser(data.user);
          Sentry.setUser(data.user);
        } catch (err) {
          toastError(err);
        }
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId) {
      const socket = socketConnection({ companyId });

      socket.on(`company-${companyId}-user`, (data) => {
        if (data.action === "update" && data.user.id === user.id) {
          setUser(data.user);
          Sentry.setUser(data.user);
        }
      });

      return () => {
        socket.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLogin = async (userData) => {
    setLoading(true);

    try {
      const { data } = await api.post("/auth/login", userData);
      const {
        user: { companyId, id, company },
      } = data;

      if (has(company, "settings") && isArray(company.settings)) {
        const setting = company.settings.find(
          (s) => s.key === "campaignsEnabled"
        );
        if (setting && setting.value === "true") {
          localStorage.setItem("cshow", null); //regra pra exibir campanhas
        }
      }
      console.log("CHECKPOINT 1");
      moment.locale("pt-br");
      const dueDate = data.user.company.dueDate;
      const hoje = moment(moment()).format("DD/MM/yyyy");
      const vencimento = moment(dueDate).format("DD/MM/yyyy");

      var diff = moment(dueDate).diff(moment(moment()).format());

      var before = moment(moment().format()).isBefore(dueDate);
      var dias = moment.duration(diff).asDays();

      if (before === true) {
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("userId", id);
        localStorage.setItem("companyDueDate", vencimento);
        api.defaults.headers.Authorization = `Bearer ${data.token}`;
        officialApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        webApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        smsApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        emailApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        shurikenApi.defaults.headers.Authorization = `Bearer ${data.token}`;
        setUser(data.user);
        Sentry.setUser(data.user);
        setIsAuth(true);
        toast.success(i18n.t("auth.toasts.success"));
        if (Math.round(dias) < 5) {
          toast.warn(
            `Sua assinatura vence em ${Math.round(dias)} ${
              Math.round(dias) === 1 ? "dia" : "dias"
            } `
          );
        }
        history.push("/tickets");
        setLoading(false);
      } else {
        console.log("Erro de assinatura");
        toastError(`Opss! Sua assinatura venceu ${vencimento}.
Entre em contato com o Suporte para mais informações! `);
        setLoading(false);
      }

      //quebra linha
    } catch (err) {
      console.error(err);
      toastError(err);
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);

    try {
      await api.delete("/auth/logout");
      Sentry.setUser(null);
      localStorage.removeItem("token");
      localStorage.removeItem("companyId");
      localStorage.removeItem("userId");
      localStorage.removeItem("cshow");
      localStorage.removeItem("settings");
      await eraseDatabase();
      api.defaults.headers.Authorization = undefined;
      officialApi.defaults.headers.Authorization = undefined;
      webApi.defaults.headers.Authorization = undefined;
      smsApi.defaults.headers.Authorization = undefined;
      emailApi.defaults.headers.Authorization = undefined;
      shurikenApi.defaults.headers.Authorization = undefined;
      setIsAuth(false);
      setUser({});
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const getCurrentUserInfo = async () => {
    try {
      const { data } = await api.get("/auth/me");
      return data;
    } catch (err) {
      toastError(err);
    }
  };

  return {
    isAuth,
    user,
    loading,
    handleLogin,
    handleLogout,
    getCurrentUserInfo,
  };
};

export default useAuth;
