import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  TextField,
} from "@material-ui/core";
import PaperContainer from "../PaperContainer";
import FileUploadInput from "../FileUploadInput";

import toastError from "../../errors/toastError";
import { useFormikContext } from "formik";

import { smsApi } from "../../services/api";

const MAX_FILE_SIZE = 100;
// Lista de encodings permitidos
const requiredHeaders = ["telefone", "cpf", "email"];
const allowedEncodings = ["utf-8", "iso-8859-1", "ascii"];

const CampaignFormSection = () => {
  const { values, setFieldValue } = useFormikContext();

  const [costCenters, setCostCenters] = useState([]);
  const [costCenterLoading, setCostCenterLoading] = useState(true);

  const fetchCostCenters = async () => {
    try {
      const result = await smsApi.get("/sms/cost-center/");
      setCostCenters(result.data);
      setCostCenterLoading(false);
    } catch (err) {
      console.error(err);
      setCostCenterLoading(false);
      toastError("Erro ao buscar os centros de custo.");
    }
  };

  const handleCampaignTitleChange = (e) => {
    setFieldValue("errors", { ...values.errors, campaignTitle: "" });
    setFieldValue("campaignTitle", e.target.value);
  };

  const handleDownloadExample = () => {
    const exampleCsv =
      "telefone;cpf;email;var1;var2\n5511999999999;12345678900;email@email.com;Carlos;Adao\n";
    const blob = new Blob([exampleCsv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "exemplo.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const detectEncoding = (text) => {
    const utf8Pattern = /^[\x00-\x7F\xC2-\xF4][\x80-\xBF]*$/;

    if (text.startsWith("\xFF\xFE\x00\x00")) return "utf-32le";
    if (text.startsWith("\x00\x00\xFE\xFF")) return "utf-32be";
    if (text.startsWith("\xFF\xFE")) return "utf-16le";
    if (text.startsWith("\xFE\xFF")) return "utf-16be";
    if (/^[\x00-\x7F]*$/.test(text)) return "ascii"; // Somente caracteres ASCII

    // Verifica se realmente é UTF-8
    if (utf8Pattern.test(text)) return "utf-8";

    return "desconhecido"; // Se não corresponder a nenhum dos padrões acima
  };

  const handleFileChange = async (event) => {
    const file = event.target?.files?.[0]; // Garante que um arquivo foi selecionado
    if (!file) return;

    setFieldValue("errors", { ...values.errors, file: false });

    const validExtensions = ["csv"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!validExtensions.includes(fileExtension) || file.type !== "text/csv") {
      setFieldValue("errors", {
        ...values.errors,
        file: "Extensão não permitida.",
      });
      setFieldValue("file", null);
      return;
    }

    const maxSize = MAX_FILE_SIZE * 1024 * 1024;
    if (file.size > maxSize) {
      setFieldValue("errors", {
        ...values.errors,
        file: "O arquivo excede o tamanho máximo permitido.",
      });
      setFieldValue("file", null);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;

      // **Validação do encoding**
      const encoding = detectEncoding(content);
      if (!allowedEncodings.includes(encoding)) {
        setFieldValue("errors", {
          ...values.errors,
          file: `Codificação inválida: ${encoding}. Permitidos: ${allowedEncodings.join(
            ", "
          )}`,
        });
        setFieldValue("file", null);
        return;
      }

      // **Dividindo linhas e verificando separador `;`**
      const lines = content.split("\n").map((line) => line.trim());
      if (!lines[0].includes(";")) {
        setFieldValue("errors", {
          ...values.errors,
          file: "O arquivo deve ter colunas separadas por ponto e vírgula (;).",
        });
        setFieldValue("file", null);
        return;
      }

      // **Verificando cabeçalhos obrigatórios**
      const headers = lines[0].split(";").map((h) => h.trim().toLowerCase());
      const missingHeaders = requiredHeaders.filter(
        (header) => !headers.includes(header)
      );
      if (missingHeaders.length > 0) {
        setFieldValue("errors", {
          ...values.errors,
          file: `Faltam os seguintes cabeçalhos obrigatórios: ${missingHeaders.join(
            ", "
          )}`,
        });
        setFieldValue("file", null);
        return;
      }

      // **Armazenando a primeira linha de dados**
      const firstDataRow =
        lines.length > 1 ? lines[1] : "Nenhuma linha disponível";
      setFieldValue("file", file);
      setFieldValue("firstDataRow", firstDataRow);
      setFieldValue("headers", headers);
      setFieldValue("requiredHeaders", requiredHeaders);
      setFieldValue("variableHeaders", headers.slice(requiredHeaders.length));
      setFieldValue("messageCount", lines.length - 1);
      setFieldValue("errors", { ...values.errors, file: "" });
    };

    reader.readAsText(file, "UTF-8");
  };

  const handleCostCenterChange = (event) => {
    setFieldValue("errors", { ...values.errors, costCenter: "" });

    const selectedId = event.target.value;

    // Busca o nome correspondente ao ID selecionado
    const selectedCenter = costCenters.find(
      (center) => center.id === selectedId
    );

    // Atualiza o estado com um objeto contendo ID e Nome
    setFieldValue("costCenter", selectedCenter);
  };

  useEffect(() => {
    fetchCostCenters();
  }, []);

  return (
    <PaperContainer>
      <Grid container spacing={2}>
        {/* Coluna da Direita - Legenda + Botões de Upload/Download */}
        <Grid item xs={12} md={6} container spacing={2} direction="column">
          {/* Botão de Download */}
          <Grid item>
            <Button variant="outlined" onClick={handleDownloadExample}>
              Download arquivo exemplo
            </Button>
          </Grid>
          {/* Legenda acima dos botões */}
          <Grid item>
            <Typography variant="body1" color="textSecondary">
              <strong>Extensões permitidas:</strong> .csv <br />
              <strong>Tamanho máximo:</strong> 100MB <br />
              <strong>Cabeçalho obrigatório</strong> <br />
              <strong>Colunas obrigatórias:</strong> telefone;cpf;email <br />
              <strong>Separador de colunas:</strong> ; (ponto e vírgula) <br />
              <strong>Codificações permitidas:</strong> UTF-8, ISO-8859-1, ASCII
            </Typography>
          </Grid>

          {/* Botões de Upload e Download */}
          <Grid item container spacing={2} alignItems="flex-end">
            {/* Upload do Arquivo */}

            <Grid item>
              <FileUploadInput
                handleFileChange={handleFileChange}
                selectedFile={values.file}
                errorMessage={values.errors.file}
              />
              {values.errors.file && (
                <Typography color="error">{values.errors.file}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Coluna da Esquerda - Título da Campanha + Centro de Custo */}
        <Grid item xs={12} md={6} container spacing={2} direction="column">
          {/* Título da Campanha */}
          <Grid item>
            <TextField
              fullWidth
              label="Título da Campanha"
              variant="outlined"
              value={values.campaignTitle}
              onChange={handleCampaignTitleChange}
              error={values.errors.campaignTitle}
            />
            {values.errors.campaignTitle && (
              <Typography color="error">O Título é obrigatório.</Typography>
            )}
          </Grid>

          {/* Centro de Custo */}
          <Grid item>
            {costCenterLoading ? (
              <CircularProgress />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                error={values.errors.costCenter}
              >
                <InputLabel id="cost-center-label">Centro de Custo</InputLabel>
                <Select
                  labelId="cost-center-label"
                  value={values.costCenter?.id}
                  onChange={handleCostCenterChange}
                  label="Centro de Custo"
                >
                  <MenuItem value="" disabled>
                    Selecione um Centro de Custo
                  </MenuItem>
                  {costCenters.map((center) => (
                    <MenuItem key={center.id} value={center.id}>
                      {center.name}
                    </MenuItem>
                  ))}
                </Select>
                {values.errors.costCenter && (
                  <Typography color="error">
                    O centro de custo é obrigatório.
                  </Typography>
                )}
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default CampaignFormSection;
