import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Paper,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CampaignTypeContext } from '../../context/CampaignTypeContext/CampaignTypeContext';

const useStyles = makeStyles(() => ({
  templateContent: {
    backgroundColor: '#f5f5f5',
    padding: '8px',
    borderRadius: '4px',
    marginTop: '8px',
    whiteSpace: 'pre-wrap',
  },
}));

const CampaignSummaryModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const { values, handleSubmit } = useFormikContext();
  const { campaignType } = useContext(CampaignTypeContext);

  const scheduled = values.scheduleStartHour && values.scheduleEndHour;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Resumo da Campanha</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Informações Gerais */}
          <Grid item xs={6}>
            <Paper style={{ padding: '16px' }}>
              <Typography>
                <strong>Nome da Campanha:</strong> {values.campaignTitle}
              </Typography>
              <Typography>
                <strong>Centro de Custo:</strong> {values.costCenter?.name}
              </Typography>
              {campaignType === 'whatsapp' && (
                <>
                  <Typography>
                    <strong>BM:</strong> {values.whatsappBusiness?.name}
                  </Typography>
                  <Typography>
                    <strong>Fila:</strong> {values.whatsappQueue?.name}
                  </Typography>
                  <Typography>
                    <strong>Bot:</strong> {values.whatsappBot?.name}
                  </Typography>
                </>
              )}
              {/* schedule_hour_start */}
              {campaignType === 'sms' && (
                <>
                  <Typography>
                    <strong>Agendamento:</strong> {scheduled ? 'Sim' : 'Não'}
                  </Typography>
                  {scheduled && (
                    <>
                      <Typography>
                        <strong>Hora Início:</strong>{' '}
                        {scheduled && values.scheduleStartHour}
                      </Typography>
                      <Typography>
                        <strong>Hora Fim:</strong>{' '}
                        {scheduled && values.scheduleEndHour}
                      </Typography>
                    </>
                  )}
                  <Typography>
                    <strong>Contador de cliques:</strong>{' '}
                    {values.useClickCounter ? 'Sim' : 'Não'}
                  </Typography>
                </>
              )}
              <Typography>
                <strong>Arquivo:</strong>{' '}
                {values.file?.name ? values.file?.name : 'Nenhum arquivo'}
              </Typography>
              <Typography>
                <strong>Quantidade de Mensagens:</strong>{' '}
                {values.file ? values.messageCount : ''}
              </Typography>
              {campaignType === 'whatsapp' && (
                <Typography
                  color={
                    values.messageCount > values.estimatedOutput
                      ? 'error'
                      : 'inherit'
                  }
                >
                  <strong>Vazão estimada:</strong> {values.estimatedOutput}
                </Typography>
              )}
            </Paper>

            {campaignType === 'whatsapp' && (
              <Box
                display="flex"
                flexDirection="column"
                style={{ gap: 8, marginTop: 8 }}
              >
                {values.whatsappPhones?.map(phone => (
                  <Paper style={{ padding: '16px' }}>
                    <Box display="flex" flexDirection="column">
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 5 }}
                      >
                        <Typography>
                          <strong>Número:</strong> {phone.displayNumber}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          <strong>{phone.messagingLimit || 'N/A'}</strong>
                        </Typography>
                      </Box>
                      <Typography variant="caption" color="textSecondary">
                        {phone.verifiedName}
                      </Typography>
                    </Box>
                  </Paper>
                ))}
              </Box>
            )}
          </Grid>

          {/* Exemplo do Template */}
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
              {campaignType === 'whatsapp' &&
                (values.whatsappTemplates.length > 0 ? (
                  values.whatsappTemplates.map(template => (
                    <Paper style={{ padding: '16px' }}>
                      <Typography>
                        <strong>Template:</strong> {template?.name}
                      </Typography>
                      <Typography>
                        <strong>Categoria:</strong> {template?.category}
                      </Typography>
                      <Typography className={classes.templateContent}>
                        {template?.body}
                      </Typography>
                    </Paper>
                  ))
                ) : (
                  <Typography>Nenhum template selecionado.</Typography>
                ))}

              {campaignType === 'sms' &&
                (values.smsTemplate?.id ? (
                  <Paper style={{ padding: '16px' }}>
                    <Typography>
                      <strong>Template:</strong> {values.smsTemplate.title}
                    </Typography>
                    <Typography className={classes.templateContent}>
                      {values.smsTemplate.content}
                    </Typography>
                  </Paper>
                ) : (
                  <Typography>Nenhum template selecionado.</Typography>
                ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Fechar
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Confirmar Campanha
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignSummaryModal;
