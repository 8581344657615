import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
} from 'react';
import { Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EmailTemplatesTable from './EmailTemplatesTable';
import EmailViewTemplateModal from './EmailViewTemplateModal';
import EmailTemplateModal from './EmailTemplateModal';
import TemplateFilters from '../TemplateFilters';
import { emailApi } from '../../../services/api';
import toastError from '../../../errors/toastError';

const initialFilters = {
  search: '',
};

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: 'scroll',
    ...theme.scrollbarStyles,
  },
}));

const EmailTemplates = forwardRef((_, ref) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  const [filters, setFilters] = useState(initialFilters);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const fetchTemplates = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const response = await emailApi.get('/email/templates/', {
          params: {
            title: filters.search || undefined,
            page: page,
          },
        });
        setTemplates(prevTemplates => {
          const templatesMap = new Map(prevTemplates.map(t => [t.id, t]));
          response.data.results.forEach(template => {
            templatesMap.set(template.id, template);
          });
          return Array.from(templatesMap.values());
        });
        setHasMore(!!response.data.next);
        setPage(page);
      } catch (err) {
        console.error(err);
        toastError(err);
      } finally {
        setLoading(false);
      }
    },
    [filters]
  );

  useEffect(() => {
    setTemplates([]);
    setLoading(true);

    const handler = setTimeout(() => {
      fetchTemplates(1);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [fetchTemplates]);

  const handleFilterChange = (field, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const loadMore = () => {
    fetchTemplates(page + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleNewTemplateClick = () => {
    setSelectedTemplateId(null);
    setIsModalOpen(true);
  };

  const handleTemplateSaved = () => {
    setTemplates([]);
    fetchTemplates(1);
  };

  const handleEditTemplate = template => {
    setSelectedTemplateId(template.id);
    setIsModalOpen(true);
  };

  const handleViewTemplate = template => {
    setSelectedTemplateId(template.id);
    setIsViewModalOpen(true);
  };

  const handleCloseTemplateModal = () => {
    setSelectedTemplateId(null);
    setIsModalOpen(false);
  };

  const handleCloseViewTemplateModal = () => {
    setSelectedTemplateId(null);
    setIsViewModalOpen(false);
  };

  useImperativeHandle(ref, () => ({
    newTemplate: handleNewTemplateClick,
  }));

  return (
    <Box className={classes.container}>
      <TemplateFilters filters={filters} onFilterChange={handleFilterChange} />

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <EmailTemplateModal
          open={isModalOpen}
          onClose={handleCloseTemplateModal}
          onSaved={handleTemplateSaved}
          templateId={selectedTemplateId}
        />

        <EmailViewTemplateModal
          open={isViewModalOpen}
          onClose={handleCloseViewTemplateModal}
          templateId={selectedTemplateId}
        />

        <EmailTemplatesTable
          loading={loading}
          templates={templates}
          onViewTemplate={handleViewTemplate}
          onEditTemplate={handleEditTemplate}
        />
      </Paper>
    </Box>
  );
});

export default EmailTemplates;
