import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

export function BusinessesFilter({ onFiltered, multiple, ...rest }) {
  const [businesses, setBusinesses] = useState([]);
  const [selecteds, setSelecteds] = useState(multiple ? [] : null);

  useEffect(() => {
    async function fetchData() {
      await loadBusinesses();
    }
    fetchData();
  }, []);

  const loadBusinesses = async () => {
    try {
      const { data } = await api.get("/businesses");
      const items = data.map((q) => ({ id: q.id, name: q.name }));
      setBusinesses(items);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Autocomplete
      {...rest}
      multiple={multiple}
      size="small"
      options={businesses}
      value={selecteds}
      onChange={(e, v, r) => onChange(v)}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => {
        return (
          option?.id === value?.id ||
          option?.name?.toLowerCase() === value?.name?.toLowerCase()
        );
      }}
      renderTags={(value, getProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            style={{
              backgroundColor: "#bfbfbf",
              textShadow: "1px 1px 1px #000",
              color: "white",
            }}
            label={option.name}
            {...getProps({ index })}
            size="small"
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Filtro por BM"
        />
      )}
    />
  );
}
