import React from "react";
import { Grid, TextField, IconButton, InputAdornment } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const FileUploadInput = ({ handleFileChange, errorMessage, selectedFile }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <input
          type="file"
          accept=".csv"
          id="upload-file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <label htmlFor="upload-file">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Selecione um arquivo"
            value={selectedFile ? selectedFile.name : ""}
            error={Boolean(errorMessage)}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton component="span">
                    <CloudUploadIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </label>
      </Grid>
    </Grid>
  );
};

export default FileUploadInput;
