import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import NewWhatsappCampaign from '../../components/NewWhatsappCampaign';
import NewSmsCampaign from '../../components/NewSmsCampaign';
import NewRcsCampaign from '../../components/NewRcsCampaign';
import NewEmailCampaign from '../../components/NewEmailCampaign';
import CampaignHeader from '../../components/CampaignHeader';
import MainContainer from '../../components/MainContainer';
import CampaignFileSection from '../../components/CampaignFileSection';

import { CampaignTypeContext } from '../../context/CampaignTypeContext/CampaignTypeContext';

import { Form, Formik } from 'formik';

import toastError from '../../errors/toastError';

import { shurikenApi, smsApi } from '../../services/api';

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    height: 'auto',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh', // Ocupa a altura total da tela
  },
}));

const NewCampaignManager = () => {
  const classes = useStyles();

  const history = useHistory();

  const { campaignType, setCampaignType } = useContext(CampaignTypeContext);

  const [createCampaignLoading, setCreateCampaignLoading] = useState(false);

  const createWhatsappCampaing = async values => {
    try {
      setCreateCampaignLoading(true);
      const formData = new FormData();

      formData.append('title', values.campaignTitle);
      formData.append('costCenterId', values.costCenter.id);
      formData.append('costCenterName', values.costCenter.name);
      formData.append('businessIds[]', values.whatsappBusiness.id);
      formData.append(
        'templateIds[]',
        values.whatsappTemplates.map(t => t.id)
      );
      formData.append('queueId', values.whatsappQueue.id);

      if (values.whatsappBot) {
        formData.append('integrationId', values.whatsappBot.id);
      }

      const blob = new Blob([values.file], { type: 'text/csv' }); // Defina o tipo correto
      const sendFile = new File([blob], values.file.name, { type: 'text/csv' });
      formData.append('file', sendFile, sendFile.name);

      await shurikenApi.post('/api/campaigns', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setCreateCampaignLoading(false);
      history.push('/campaigns');
    } catch (err) {
      toastError('Erro ao criar a campanha. Contate a equipe de suporte.');
      setCreateCampaignLoading(false);
      console.error(err);
    }
  };

  const createSmsCampaign = async values => {
    try {
      setCreateCampaignLoading(true);

      const data = {
        title: values.campaignTitle,
        cost_center_id: values.costCenter.id,
        template: values.smsTemplate.id,
        filename: values.file.name,
        use_file: true,
        use_whitelist: false,
        use_blacklist_nexus: false,
        use_shortener_url: values.useClickCounter,
        tags: [],
        contact_variable_index: 0,
        cpf_variable_index: 1,
        matrix_variables: values.smsMatrixVariables,
      };

      if (values.scheduleStartHour && values.scheduleEndHour) {
        data.schedule_date = new Date().toLocaleDateString('pt-BR');
        data.start_hour = values.scheduleStartHour;
        data.end_hour = values.scheduleEndHour;
      }

      const response = await smsApi.post('/sms/campaigns/', data);

      await fetch(response.data.upload_url, {
        method: 'PUT',
        body: values.file,
        headers: { 'Content-Type': 'text/csv' },
      });

      setCreateCampaignLoading(false);
      history.push('/campaigns');
    } catch (err) {
      toastError('Erro ao criar a campanha. Contate a equipe de suporte.');
      setCreateCampaignLoading(false);
      console.error(err);
    }
  };

  const handleSubmit = values => {
    if (campaignType === 'whatsapp') {
      createWhatsappCampaing(values);
    } else if (campaignType === 'sms') {
      createSmsCampaign(values);
    }
  };

  return (
    <Formik
      initialValues={{
        costCenter: null,
        campaignTitle: '',
        file: null,

        whatsappBusiness: null,
        whatsappTemplates: [],
        whatsappQueue: null,
        whatsappBot: null,

        errors: {
          costCenter: '',
          campaignTitle: '',
          file: '',

          whatsappBusiness: '',
          whatsappTemplates: '',
          whatsappQueue: '',
          whatsappBot: '',

          smsTemplate: '',
        },
      }}
      // validationSchema={validationSchema}
      onSubmit={values => handleSubmit(values)}
    >
      <MainContainer
        maxWidth="lg"
        contentWrapperClassName={classes.contentWrapper}
      >
        {/* Dropdown para selecionar o tipo de campanha */}
        <CampaignHeader
          title="Nova Campanha"
          campaignType={campaignType}
          handleCampaignTypeChange={e => setCampaignType(e.target.value)}
        />
        {createCampaignLoading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress size={50} />
          </div>
        ) : (
          <>
            <CampaignFileSection />

            {campaignType === 'whatsapp' && <NewWhatsappCampaign />}
            {campaignType === 'sms' && <NewSmsCampaign />}
          </>
        )}
      </MainContainer>
    </Formik>
  );
};

export default NewCampaignManager;
