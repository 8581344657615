import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';

import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import CostCenterModal from '../../components/CostCenterModal';
import Title from '../../components/Title';

import { smsApi } from '../../services/api';
import { i18n } from '../../translate/i18n';
import toastError from '../../errors/toastError';

const reducer = (state, action) => {
  if (action.type === 'LOAD_COST_CENTERS') {
    const costCenters = action.payload;
    const newCostCenters = [];

    costCenters.forEach(costCenter => {
      const costCenterIndex = state.findIndex(cc => cc.id === costCenter.id);
      if (costCenterIndex !== -1) {
        state[costCenterIndex] = costCenter;
      } else {
        newCostCenters.push(costCenter);
      }
    });

    return [...state, ...newCostCenters];
  }

  if (action.type === 'RESET') {
    return [];
  }
};

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: 'scroll',
    ...theme.scrollbarStyles,
  },
  queuesFilter: {
    minWidth: 300,
  },
}));

const CostCenters = () => {
  const classes = useStyles();

  const [costCenters, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchParam, setSearchParam] = useState('');

  const fetchCostCenters = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await smsApi.get('/sms/cost-center/', {
        params: {
          name: searchParam || undefined,
        },
      });
      dispatch({ type: 'LOAD_COST_CENTERS', payload: data });
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  }, [searchParam]);

  useEffect(() => {
    dispatch({ type: 'RESET' });
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchCostCenters();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [fetchCostCenters]);

  const handleOpenCostCenterModal = () => {
    setSelectedCostCenter(null);
    setIsModalOpen(true);
  };

  const handleCloseCostCenterModal = () => {
    setIsModalOpen(false);
  };

  const handleSearch = event => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditCostCenter = costCenter => {
    setSelectedCostCenter(costCenter);
    setIsModalOpen(true);
  };

  const handleRefreshCostCenters = () => {
    dispatch({ type: 'RESET' });
    fetchCostCenters();
  };

  return (
    <MainContainer>
      <CostCenterModal
        open={isModalOpen}
        onClose={handleCloseCostCenterModal}
        onSaved={handleRefreshCostCenters}
        aria-labelledby="form-dialog-title"
        costCenter={selectedCostCenter}
      />
      <MainHeader>
        <Title>{i18n.t('costCenters.title')}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t('costCenters.filters.search')}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: 'gray' }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCostCenterModal}
          >
            {i18n.t('costCenters.buttons.add')}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t('costCenters.table.id')}
              </TableCell>
              <TableCell align="center">
                {i18n.t('costCenters.table.name')}
              </TableCell>
              <TableCell align="center">
                {i18n.t('costCenters.table.actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {costCenters.map(costCenter => (
                <TableRow key={costCenter.id}>
                  <TableCell align="center">{costCenter.id}</TableCell>
                  <TableCell align="center">{costCenter.name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditCostCenter(costCenter)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default CostCenters;
