import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TableRowSkeleton from '../../TableRowSkeleton';
import { i18n } from '../../../translate/i18n';

const EmailTemplatesTable = ({
  loading,
  templates,
  onViewTemplate,
  onEditTemplate,
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center">{i18n.t('templates.table.name')}</TableCell>
          <TableCell align="center">
            {i18n.t('templates.table.updatedAt')}
          </TableCell>
          <TableCell align="center">
            {i18n.t('templates.table.actions')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {templates.map(template => (
          <TableRow key={template.id}>
            <TableCell align="center">{template.title}</TableCell>
            <TableCell align="center">{template.updated_at}</TableCell>
            <TableCell align="center">
              <Grid container justifyContent="center" spacing={1}>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => onViewTemplate(template)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => onEditTemplate(template)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
        {loading && <TableRowSkeleton columns={3} />}
      </TableBody>
    </Table>
  );
};

export default EmailTemplatesTable;
