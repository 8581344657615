import React, { useCallback, useContext, useRef, useState } from 'react';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import { CampaignTypeContext } from '../../context/CampaignTypeContext/CampaignTypeContext';
import CampaignHeader from '../../components/CampaignHeader';
import SmsTemplates from '../../components/Templates/Sms/SmsTemplates';
import EmailTemplates from '../../components/Templates/Email/EmailTemplates';
import WhatsAppTemplates from '../../components/Templates/WhatsApp/WhatsAppTemplates';
import { i18n } from '../../translate/i18n';

const componentMap = {
  whatsapp: WhatsAppTemplates,
  sms: SmsTemplates,
  email: EmailTemplates,
};

const Templates = () => {
  const { campaignType, setCampaignType } = useContext(CampaignTypeContext);
  const [hasAddTemplate, setHasAddTemplate] = useState(false);
  const templatesRef = useRef(null);

  const handleCampaignTypeChange = event => {
    setCampaignType(event.target.value);
  };

  const handleNewTemplateClick = () => {
    if (templatesRef.current) {
      templatesRef.current.newTemplate();
    }
  };

  const setTemplatesRef = useCallback(ref => {
    templatesRef.current = ref;
    setHasAddTemplate(!!ref?.newTemplate);
  }, []);

  const Templates = componentMap[campaignType];

  return (
    <MainContainer>
      <MainHeader>
        <CampaignHeader
          title={i18n.t('templates.title')}
          campaignType={campaignType}
          handleCampaignTypeChange={handleCampaignTypeChange}
          actionTitle={hasAddTemplate && i18n.t('templates.add')}
          handleAction={hasAddTemplate && handleNewTemplateClick}
        />
      </MainHeader>

      {Templates && <Templates ref={setTemplatesRef} />}
    </MainContainer>
  );
};

export default Templates;
