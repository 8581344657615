import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Tooltip,
  Box,
  Badge,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageIcon from '@material-ui/icons/Image';
import PaperContainer from '../../components/PaperContainer';
import CampaignSummaryModal from '../../components/CampaignSummaryModal';

import toastError from '../../errors/toastError';

import { useFormikContext } from 'formik';

import api, { shurikenApi } from '../../services/api';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: theme.spacing(2),
    minHeight: '100px',
    maxHeight: '250px',
    overflowY: 'auto',
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
  },
  caption: {
    lineHeight: 1.2,
  },
  badge: {
    color: '#FFF',
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: '0.9em',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    backgroundColor: '#888',
  },
}));

const NewWhatsappCampaign = () => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();

  const [businesses, setBusinesses] = useState([]);
  const [queues, setQueues] = useState([]);
  const [bots, setBots] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [phones, setPhones] = useState([]);

  const [summaryOpen, setSummaryOpen] = useState(false);
  const [campaignData, setCampaignData] = useState(null);

  const [businessIsLoading, setBusinessIsLoading] = useState(true);
  const [queueIsLoading, setQueueIsLoading] = useState(true);
  const [botIsLoading, setBotIsLoading] = useState(true);
  const [templateIsLoading, setTemplateIsLoading] = useState(false);

  const fetchTemplates = async businessId => {
    try {
      const result = await shurikenApi.get(
        `/api/templates/?BusinessId=${businessId}&active=true&isValid=true`
      );
      setTemplates(result.data);
      setTemplateIsLoading(false);
    } catch (err) {
      toastError('Erro ao buscar os templates. Contate a equipe de suporte.');
      setTemplateIsLoading(false);
      console.error(err);
    }
  };

  const fetchPhones = async businessId => {
    try {
      const result = await shurikenApi.get(
        `/api/phones/?BusinessId=${businessId}&output=true`
      );
      setPhones(result.data);
    } catch (err) {
      toastError('Erro ao buscar números. Contate a equipe de suporte.');
      console.error(err);
    }
  };

  const fetchBusiness = async () => {
    try {
      const result = await shurikenApi.get('/api/businesses');
      setBusinesses(result.data);
      setBusinessIsLoading(false);
    } catch (err) {
      setBusinessIsLoading(false);
      toastError('Erro ao buscar os BMs. Contate a equipe de suporte.');
      console.error(err);
    }
  };

  const fetchQueues = async () => {
    try {
      const result = await api.get('/queue');
      setQueues(result.data);
      setQueueIsLoading(false);
    } catch (err) {
      setQueueIsLoading(false);
      toastError('Erro ao buscar as filas. Contate a equipe de suporte.');
      console.error(err);
    }
  };

  const fetchBots = async () => {
    try {
      const result = await api.get('/queueIntegration?limit=all');
      setBots(result.data.queueIntegrations);
      setBotIsLoading(false);
    } catch (err) {
      setBotIsLoading(false);
      toastError('Erro ao buscar os Bots. Contate a equipe de suporte.');
      console.error(err);
    }
  };

  const handleBusinessChange = event => {
    setFieldValue('errors', {
      ...values.errors,
      whatsappBusiness: '',
    });

    const selectedWhastappBusiness = businesses.find(
      business => business.id === event.target.value
    );

    setFieldValue('whatsappBusiness', selectedWhastappBusiness);
    setFieldValue('whatsappTemplates', []);
    setFieldValue('whatsappPhones', []);
    setFieldValue('estimatedOutput', 0);
    fetchTemplates(selectedWhastappBusiness.id);
    fetchPhones(selectedWhastappBusiness.id);
  };

  const handleTemplateSelection = id => {
    setFieldValue('errors', {
      ...values.errors,
      whatsappTemplates: '',
    });

    const selectedTemplate = templates.find(template => template.id === id);

    if (!selectedTemplate) return; // Evita erros se o template não for encontrado

    // Verifica se já existe em values.whatsappTemplates, se existir tira, se não adiciona
    const exists = values.whatsappTemplates.some(
      template => template.id === id
    );

    const newTemplates = exists
      ? values.whatsappTemplates.filter(template => template.id !== id) // Remove se já existir
      : [...values.whatsappTemplates, selectedTemplate]; // Adiciona se não existir

    const selectedPhoneIds = new Set();

    for (const template of newTemplates) {
      for (const phone of template.PhoneNumbers) {
        selectedPhoneIds.add(phone.id);
      }
    }

    let estimatedOutput = 0;
    const whatsappPhones = [];

    for (const phone of phones) {
      if (selectedPhoneIds.has(phone.id)) {
        estimatedOutput += phone.remainingOutput;
        whatsappPhones.push(phone);
      }
    }

    setFieldValue('whatsappTemplates', newTemplates);
    setFieldValue('whatsappPhones', whatsappPhones);
    setFieldValue('estimatedOutput', estimatedOutput);
  };

  const handleQueueChange = event => {
    setFieldValue('errors', {
      ...values.errors,
      whatsappQueue: '',
    });

    const selectedQueue = queues.find(queue => queue.id === event.target.value);

    setFieldValue('whatsappQueue', selectedQueue);
  };

  const handleBotChange = event => {
    setFieldValue('errors', {
      ...values.errors,
      whatsappBot: '',
    });

    const selectedBot = bots.find(bot => bot.id === event.target.value);
    setFieldValue('whatsappBot', selectedBot);
  };

  const handleOpenSummary = () => {
    let newErrors = {
      costCenter:
        values.costCenter === null ? 'O centro de custo é obrigatório.' : null,
      campaignTitle:
        values.campaignTitle === ''
          ? 'O título da campanha é obrigatório.'
          : null,
      file: values.file === null ? 'O arquivo é obrigatório.' : null,

      whatsappBusiness:
        values.whatsappBusiness === null ? 'O BM é obrigatório.' : null,
      whatsappTemplates:
        values.whatsappTemplates.length === 0
          ? 'O template é obrigatório.'
          : null,
      whatsappQueue:
        values.whatsappQueue === null ? 'A fila é obrigatória.' : null,
    };

    setFieldValue('errors', newErrors);

    // Se algum campo estiver inválido, não abre o modal
    if (Object.values(newErrors).some(error => error)) {
      return;
    }

    setSummaryOpen(true);
  };

  useEffect(() => {
    fetchBusiness();
    fetchQueues();
    fetchBots();
  }, []);

  return (
    <>
      <PaperContainer>
        <Grid container spacing={2}>
          {/* Selecione o BM */}
          <Grid item xs={4}>
            {businessIsLoading ? (
              <CircularProgress />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                error={values.errors.whatsappBusiness}
              >
                <InputLabel id="business-label">Selecione um BM</InputLabel>
                <Select
                  labelId="business-label"
                  value={values.whatsappBusiness?.id}
                  onChange={handleBusinessChange}
                  label="Selecione um BM"
                >
                  <MenuItem value="" disabled>
                    Selecione um BM
                  </MenuItem>
                  {businesses?.map(business => (
                    <MenuItem key={business.id} value={business.id}>
                      {business.name}
                    </MenuItem>
                  ))}
                </Select>
                {values.errors.whatsappBusiness && (
                  <Typography color="error">
                    {values.errors.whatsappBusiness}
                  </Typography>
                )}
              </FormControl>
            )}
          </Grid>

          {/* Selecione uma Fila */}
          <Grid item xs={4}>
            {queueIsLoading ? (
              <CircularProgress />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                error={values.errors.whatsappQueue}
              >
                <InputLabel id="queue-label">Selecione uma Fila</InputLabel>
                <Select
                  labelId="queue-label"
                  value={values.whatsappQueue?.id}
                  onChange={handleQueueChange}
                  label="Selecione uma Fila"
                >
                  <MenuItem value="" disabled>
                    Selecione uma Fila
                  </MenuItem>
                  {queues.map(queue => (
                    <MenuItem key={`queue_${queue.id}`} value={queue.id}>
                      {queue.name}
                    </MenuItem>
                  ))}
                </Select>
                {values.errors.whatsappQueue && (
                  <Typography color="error">
                    {values.errors.whatsappQueue}
                  </Typography>
                )}
              </FormControl>
            )}
          </Grid>

          {/* Selecione o Bot */}
          <Grid item xs={4}>
            {botIsLoading ? (
              <CircularProgress />
            ) : (
              <FormControl fullWidth variant="outlined">
                <InputLabel id="bot-label">Selecione um BOT</InputLabel>
                <Select
                  id="bot-label"
                  value={values.whatsappBot?.id}
                  onChange={handleBotChange}
                  label="Selecione um BOT"
                >
                  <MenuItem value="" disabled>
                    Selecione um BOT
                  </MenuItem>
                  {bots.map(bot => (
                    <MenuItem key={`bot_${bot.id}`} value={bot.id}>
                      {bot.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </PaperContainer>
      <PaperContainer>
        {/* Templates - Ocupa toda a linha abaixo */}
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: '15px' }}>
            Templates
          </Typography>
          <FormGroup className={classes.grid}>
            {templates.map(template => (
              <FormControlLabel
                key={template.id}
                control={
                  <Checkbox
                    checked={values.templates?.includes(template.id)}
                    onChange={() => handleTemplateSelection(template.id)}
                  />
                }
                label={
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                      <Typography>{template.name}</Typography>
                      {template.type === 'IMAGE' && (
                        <Tooltip title="Este template contém imagem" arrow>
                          <ImageIcon fontSize="small" />
                        </Tooltip>
                      )}
                      <Tooltip title={<Typography>{template.body}</Typography>}>
                        <VisibilityIcon fontSize="small" />
                      </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                      <Badge className={classes.badge}>
                        <Typography
                          variant="caption"
                          className={classes.caption}
                        >
                          {template.category}
                        </Typography>
                      </Badge>
                      <Badge className={classes.badge}>
                        <Tooltip
                          title="Quantidade de variáveis do template"
                          arrow
                        >
                          <Typography
                            variant="caption"
                            className={classes.caption}
                          >
                            {template.type === 'IMAGE' && 'IMAGEM + '}
                            {template.variableCount}
                          </Typography>
                        </Tooltip>
                      </Badge>
                    </Box>
                  </Box>
                }
              />
            ))}
          </FormGroup>
          {values.errors.whatsappTemplates && (
            <Typography color="error">
              {values.errors.whatsappTemplates}
            </Typography>
          )}
          {templates.length > 0 && (
            <Typography variant="subtitle1" style={{ marginTop: '8px' }}>
              <strong>Vazão estimada dos templates selecionados:</strong>{' '}
              {values.estimatedOutput || 0}
            </Typography>
          )}
        </Grid>
      </PaperContainer>
      <PaperContainer>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: '15px' }}>
            Números
          </Typography>
          <Box className={classes.grid} style={{ padding: '12px' }}>
            {values.whatsappPhones?.map(phone => (
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                  <Typography>{phone.displayNumber}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    <strong>{phone.messagingLimit || 'N/A'}</strong>
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.caption}
                >
                  {phone.verifiedName}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </PaperContainer>
      {/* Botões de Ação */}
      <PaperContainer>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenSummary}
            >
              Criar Campanha
            </Button>
          </Grid>
        </Grid>
      </PaperContainer>
      <CampaignSummaryModal
        open={summaryOpen}
        handleClose={() => setSummaryOpen(false)}
      />
    </>
  );
};

export default NewWhatsappCampaign;
