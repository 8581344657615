import React from 'react';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

import 'htmlhint';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/html-lint';
import 'codemirror/addon/lint/css-lint';
import 'codemirror/addon/lint/javascript-lint';

import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/html-hint';
import 'codemirror/addon/hint/css-hint';
import 'codemirror/addon/hint/javascript-hint';

import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/matchtags';

import { Controlled as ControlledEditor } from 'react-codemirror2';

import './styles.css';

const CodeEditor = ({ language, value, onChange }) => {
  const handleChange = (editor, data, value) => {
    onChange(value);
  };

  return (
    <ControlledEditor
      onBeforeChange={handleChange}
      value={value}
      className="code-mirror-wrapper"
      options={{
        mode: language,
        theme: 'material',
        lineNumbers: true,
        gutters: ['CodeMirror-lint-markers'],
        lint: true,
        extraKeys: { 'Ctrl-Space': 'autocomplete' },
        autoCloseTags: true,
        autoCloseBrackets: true,
        matchBrackets: true,
      }}
    />
  );
};
export default CodeEditor;
