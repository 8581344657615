import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { i18n } from '../../../translate/i18n';
import { smsApi } from '../../../services/api';
import toastError from '../../../errors/toastError';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  multFieldLine: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const initialState = {
  type: 'sms',
  title: '',
  content: '',
};

const SmsViewTemplateModal = ({ open, onClose, templateId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(initialState);

  useEffect(() => {
    let mounted = true;

    const fetchTemplate = async () => {
      setLoading(true);
      try {
        const { data } = await smsApi.get(`/sms/templates/${templateId}/`);
        if (mounted) {
          setTemplate(prev => ({ ...prev, ...data }));
        }
      } catch (err) {
        if (mounted) {
          toastError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    if (open && templateId) {
      fetchTemplate();
    }

    return () => (mounted = false);
  }, [templateId, open]);

  const handleClose = () => {
    onClose();
    setTemplate(initialState);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {i18n.t('templateModal.title.view')}
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            visibility: loading ? 'hidden' : 'visible',
            position: 'relative',
          }}
        >
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              visibility="visible"
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
            >
              <CircularProgress />
            </Box>
          )}

          <FormControl variant="outlined" margin="dense" fullWidth disabled>
            <InputLabel id="template-type-label">
              {i18n.t('templateModal.form.type')}
            </InputLabel>
            <Select
              label={i18n.t('templateModal.form.type')}
              placeholder={i18n.t('templateModal.form.type')}
              labelId="template-type-label"
              value="sms"
              id="type"
              name="type"
              disabled
            >
              <MenuItem value="sms">SMS</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label={i18n.t('templateModal.form.title')}
            value={template.title}
            autoFocus
            name="title"
            variant="outlined"
            margin="dense"
            fullWidth
            inputProps={{ readOnly: true }}
          />

          <TextField
            label={i18n.t('templateModal.form.content')}
            value={template.content}
            name="content"
            variant="outlined"
            margin="dense"
            multiline={true}
            rows={7}
            fullWidth
            inputProps={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            className={classes.btnWrapper}
            onClick={handleClose}
          >
            {i18n.t('templateModal.buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SmsViewTemplateModal;
