import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  Badge,
  Typography,
  Tooltip,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageIcon from '@material-ui/icons/Image';
import TableRowSkeleton from '../../TableRowSkeleton';
import StatusBadge from '../../StatusBadge';
import { i18n } from '../../../translate/i18n';

const useStyles = makeStyles(() => ({
  badge: {
    color: '#FFF',
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: '0.9em',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    backgroundColor: '#333',
  },
}));

const CATEGORY_COLOR_MAP = {
  AUTHENTICATION: '#166534',
  MARKETING: '#854d0e',
  UTILITY: '#3730a3',
};

const WhatsAppTemplatesTable = ({ loading, templates, onViewTemplate }) => {
  const classes = useStyles();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center">{i18n.t('templates.table.id')}</TableCell>
          <TableCell align="center">{i18n.t('templates.table.name')}</TableCell>
          <TableCell align="center">
            {i18n.t('templates.table.status')}
          </TableCell>
          <TableCell align="center">
            {i18n.t('templates.table.category')}
          </TableCell>
          <TableCell align="center">
            {i18n.t('templates.table.image')}
          </TableCell>
          <TableCell align="center">{i18n.t('templates.table.bm')}</TableCell>
          <TableCell align="center">{i18n.t('templates.table.type')}</TableCell>
          <TableCell align="center">
            {i18n.t('templates.table.variableCount')}
          </TableCell>
          <TableCell align="center">
            {i18n.t('templates.table.actions')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {templates.map(template => (
          <TableRow key={template.id}>
            <TableCell align="center">{template.id}</TableCell>
            <TableCell align="center">{template.name}</TableCell>
            <TableCell align="center">
              <Tooltip
                title={
                  <Typography variant="body2">
                    {template.validation?.message || 'Template ativo'}
                  </Typography>
                }
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  padding="8px"
                >
                  <StatusBadge
                    status={template.validation?.valid ? 'ACTIVE' : 'DISABLED'}
                  />
                </Box>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Badge
                style={{
                  backgroundColor: CATEGORY_COLOR_MAP[template.category],
                }}
                className={classes.badge}
              >
                {template.category}
              </Badge>
            </TableCell>
            <TableCell align="center">
              {template.type === 'IMAGE' && (
                <Tooltip title={i18n.t('templates.tooltip.image')} arrow>
                  <ImageIcon fontSize="small" />
                </Tooltip>
              )}
            </TableCell>
            <TableCell align="center">{template.Business?.name}</TableCell>
            <TableCell align="center">{template.TemplateType?.name}</TableCell>
            <TableCell align="center">
              <Box
                display="flex"
                padding="8px"
                alignItems="center"
                justifyContent="center"
                style={{ gap: '4px' }}
              >
                {template.type === 'IMAGE' && (
                  <>
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          Para templates com imagem, a URL da imagem deve ser
                          sempre a primeira variável após as colunas
                          obrigatórias.
                        </Typography>
                      }
                      arrow
                    >
                      <Badge className={classes.badge}>IMAGEM</Badge>
                    </Tooltip>
                    <span>+</span>
                  </>
                )}
                <span>{template.variableCount}</span>
              </Box>
            </TableCell>
            <TableCell align="center">
              <Grid container justifyContent="center" spacing={1}>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => onViewTemplate(template)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
        {loading && <TableRowSkeleton columns={8} />}
      </TableBody>
    </Table>
  );
};

export default WhatsAppTemplatesTable;
