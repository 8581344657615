import { Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import toastError from '../../errors/toastError';
import { shurikenApi } from '../../services/api';

export function TemplateTypeFilter({ onFiltered, multiple, ...rest }) {
  const [items, setItems] = useState([]);
  const [selecteds, setSelecteds] = useState(multiple ? [] : null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const { data } = await shurikenApi.get('/api/template-types');
      const items = data.map(q => ({ id: q.id, name: q.name }));
      setItems(items);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async value => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Autocomplete
      {...rest}
      multiple={multiple}
      size="small"
      options={items}
      value={selecteds}
      onChange={(e, v, r) => onChange(v)}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => {
        return (
          option?.id === value?.id ||
          option?.name?.toLowerCase() === value?.name?.toLowerCase()
        );
      }}
      renderTags={(value, getProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            style={{
              backgroundColor: '#bfbfbf',
              textShadow: '1px 1px 1px #000',
              color: 'white',
            }}
            label={option.name}
            {...getProps({ index })}
            size="small"
          />
        ))
      }
      renderInput={params => (
        <TextField {...params} variant="outlined" placeholder="Tipo" />
      )}
    />
  );
}
