import React, { useEffect, useState } from "react";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { smsApi } from "../../services/api";

import toastError from "../../errors/toastError";

const CampaignsListFilters = ({
  status,
  setStatus,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  costCenter,
  setCostCenter,
}) => {
  const [costCenters, setCostCenters] = useState([]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleCostCenterChange = (e) => {
    const selectedCostCenter = costCenters.find(
      (cc) => cc.id === e.target.value
    );
    setCostCenter(selectedCostCenter);
  };

  const fetchCostCenters = async () => {
    try {
      const result = await smsApi.get("/sms/cost-center/");
      setCostCenters(result.data);
    } catch (err) {
      console.error(err);
      toastError("Erro ao buscar os centros de custo");
    }
  };

  useEffect(() => {
    fetchCostCenters();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{
        marginBottom: "15px",
        padding: "0 16px",
        minHeight: "56px",
      }}
    >
      {/* Status */}
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select value={status} onChange={handleStatusChange} label="Status">
            <MenuItem value="">Todos os Status</MenuItem>
            <MenuItem value="READY">Pronta</MenuItem>
            <MenuItem value="ANALYZING">Em Análise</MenuItem>
            {/* <MenuItem value="CANCELLED">Cancelada</MenuItem> */}
            <MenuItem value="ERROR">Erro</MenuItem>
            <MenuItem value="RUNNING">Em Disparo</MenuItem>
            <MenuItem value="FINISHED">Finalizada</MenuItem>
            <MenuItem value="WAITING">Aguardando</MenuItem>
            {/* <MenuItem value="PAUSED">Pausada</MenuItem> */}
          </Select>
        </FormControl>
      </Grid>

      {/* Data de Início */}
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            label="Data Início"
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </Grid>

      {/* Data de Fim */}
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            label="Data Fim"
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </Grid>

      {/* Centro de Custo */}
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Centro de Custo</InputLabel>
          <Select
            value={costCenter?.id}
            onChange={handleCostCenterChange}
            label="Centro de Custo"
          >
            <MenuItem value="">Todos os Centros de Custo</MenuItem>
            {costCenters.map((cc) => (
              <MenuItem key={cc.id} value={cc.id}>
                {cc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CampaignsListFilters;
