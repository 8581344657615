import React from "react";
import {
  IconButton,
  Select,
  MenuItem,
  Paper,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Title from "../../components/Title";

const CampaignHeader = ({
  title,
  campaignType,
  handleCampaignTypeChange,
  handleAction,
  actionTitle,
}) => {
  return (
    <>
      {/* Título fora do Paper */}
      <Title>{title}</Title>

      {/* Paper contendo o seletor de campanha e o botão de adicionar */}
      <Paper
        style={{
          width: "100%",
          padding: "16px",
          marginBottom: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Seletor de Tipo de Campanha com Label */}
        <FormControl style={{ minWidth: 200 }}>
          <InputLabel id="campaign-type-label">Canal</InputLabel>
          <Select
            labelId="campaign-type-label"
            value={campaignType}
            onChange={handleCampaignTypeChange}
          >
            <MenuItem value="whatsapp">WhatsApp</MenuItem>
            <MenuItem value="sms">SMS</MenuItem>
            {/* <MenuItem value="rcs">RCS</MenuItem> */}
            <MenuItem value="email">E-mail</MenuItem>
          </Select>
        </FormControl>

        {/* Botão de Adicionar Redondo */}
        {actionTitle && (
          <IconButton
            color="primary"
            onClick={handleAction}
            style={{ backgroundColor: "#000000", color: "white" }}
          >
            <AddIcon />
          </IconButton>
        )}
      </Paper>
    </>
  );
};

export default CampaignHeader;
