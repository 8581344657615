import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { i18n } from '../../translate/i18n';
import { smsApi } from '../../services/api';
import toastError from '../../errors/toastError';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  btnWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Schema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Too Short!')
    .max(64, 'Too Long!')
    .required('Required'),
});

const initialData = {
  name: '',
};

const CostCenterModal = ({ open, onClose, onSaved, costCenter }) => {
  const classes = useStyles();
  const [data, setData] = useState(initialData);

  console.log('data', data);

  useEffect(() => {
    if (open) {
      setData(costCenter || initialData);
    }
  }, [costCenter, open]);

  const handleClose = () => {
    onClose();
  };

  const handleSaved = () => {
    onSaved();
    handleClose();
  };

  const handleSaveCostCenter = async values => {
    const costCenterData = {
      name: values.name,
    };
    try {
      if (costCenter) {
        await smsApi.put(`/sms/cost-center/${costCenter.id}/`, costCenterData);
      } else {
        await smsApi.post('/sms/cost-center/', costCenterData);
      }
      toast.success(i18n.t('costCenterModal.success'));
      handleSaved();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {costCenter
            ? `${i18n.t('costCenterModal.title.edit')}`
            : `${i18n.t('costCenterModal.title.add')}`}
        </DialogTitle>
        <Formik
          initialValues={data}
          enableReinitialize={true}
          validationSchema={Schema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveCostCenter(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t('costCenterModal.form.name')}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t('costCenterModal.buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {costCenter
                    ? `${i18n.t('costCenterModal.buttons.okEdit')}`
                    : `${i18n.t('costCenterModal.buttons.okAdd')}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default CostCenterModal;
