import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { i18n } from '../../../translate/i18n';
import { smsApi } from '../../../services/api';
import toastError from '../../../errors/toastError';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  multFieldLine: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: 'relative',
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const TemplateSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, i18n.t('templateModal.validation.sms.title.min'))
    .max(256, i18n.t('templateModal.validation.sms.title.max'))
    .required(i18n.t('templateModal.validation.sms.title.required')),
  content: Yup.string()
    .min(2, i18n.t('templateModal.validation.sms.content.min'))
    .max(1024, i18n.t('templateModal.validation.sms.content.max'))
    .required(i18n.t('templateModal.validation.sms.content.required')),
});

const initialState = {
  type: 'sms',
  title: '',
  content: '',
};

const SmsTemplateModal = ({ open, onClose, onSaved, templateId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(initialState);

  useEffect(() => {
    let mounted = true;

    const fetchTemplate = async () => {
      setLoading(true);
      try {
        const { data } = await smsApi.get(`/sms/templates/${templateId}/`);
        if (mounted) {
          setTemplate(prev => ({ ...prev, ...data }));
        }
      } catch (err) {
        if (mounted) {
          toastError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    if (open && templateId) {
      fetchTemplate();
    }

    return () => (mounted = false);
  }, [templateId, open]);

  const handleClose = () => {
    onClose();
    setTemplate(initialState);
  };

  const handleSaved = () => {
    onSaved();
    onClose();
  };

  const handleSaveTemplate = async values => {
    try {
      const data = {
        title: values.title,
        content: values.content,
      };

      if (templateId) {
        await smsApi.put(`/sms/templates/${templateId}/`, data);
      } else {
        await smsApi.post('/sms/templates/', data);
      }

      toast.success(i18n.t('templateModal.success'));
      handleSaved();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {templateId
            ? i18n.t('templateModal.title.edit')
            : i18n.t('templateModal.title.add')}
        </DialogTitle>
        <Formik
          initialValues={template}
          enableReinitialize={true}
          validationSchema={TemplateSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveTemplate(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent
                dividers
                style={{
                  visibility: loading ? 'hidden' : 'visible',
                  position: 'relative',
                }}
              >
                {loading && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    visibility="visible"
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                  >
                    <CircularProgress />
                  </Box>
                )}

                <FormControl
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  disabled
                >
                  <InputLabel id="template-type-label">
                    {i18n.t('templateModal.form.type')}
                  </InputLabel>
                  <Field
                    as={Select}
                    label={i18n.t('templateModal.form.type')}
                    placeholder={i18n.t('templateModal.form.type')}
                    labelId="template-type-label"
                    id="type"
                    name="type"
                    error={touched.type && Boolean(errors.type)}
                    disabled
                  >
                    <MenuItem value="sms">SMS</MenuItem>
                    <MenuItem value="whatsapp">WhatsApp</MenuItem>
                    <MenuItem value="email">E-mail</MenuItem>
                  </Field>
                </FormControl>

                <Field
                  as={TextField}
                  label={i18n.t('templateModal.form.title')}
                  autoFocus
                  name="title"
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />

                <Field
                  as={TextField}
                  label={i18n.t('templateModal.form.content')}
                  name="content"
                  error={touched.content && Boolean(errors.content)}
                  helperText={touched.content && errors.content}
                  variant="outlined"
                  margin="dense"
                  multiline={true}
                  rows={7}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t('templateModal.buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {templateId
                    ? i18n.t('templateModal.buttons.okEdit')
                    : i18n.t('templateModal.buttons.okAdd')}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default SmsTemplateModal;
