import React, { useEffect, useState, useRef } from 'react';
import { Box, Button } from '@material-ui/core';
import DevicesIcon from '@material-ui/icons/Devices';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  mobileButton: ({ isMobileView }) => ({
    position: 'absolute',
    top: 8,
    right: 8,
    height: 44,
    width: 58,
    minWidth: 58,
    backgroundColor: isMobileView ? undefined : 'white',
  }),
  iframe: ({ isMobileView }) => ({
    flex: 1,
    border: '1px solid #ccc',
    display: 'block',
    width: isMobileView ? '375px' : '100%',
    height: isMobileView ? '667px' : '100%',
    margin: isMobileView ? 'auto' : '0',
  }),
}));

const EmailFrame = ({ content }) => {
  const [isMobileView, setIsMobileView] = useState(false);
  const iframeRef = useRef(null);
  const classes = useStyles({ isMobileView });

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentDocument) {
      iframe.contentDocument.open();
      iframe.contentDocument.write(content);
      iframe.contentDocument.close();
    }
  }, [content]);

  return (
    <Box className={classes.container}>
      <Button
        className={classes.mobileButton}
        variant={isMobileView ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => setIsMobileView(prev => !prev)}
      >
        <DevicesIcon />
      </Button>

      <iframe
        ref={iframeRef}
        className={classes.iframe}
        title="template content"
        srcDoc={content}
      />
    </Box>
  );
};

export default EmailFrame;
