import React, { useCallback, useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Chip,
  Button,
} from '@material-ui/core';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PauseIcon from '@material-ui/icons/Pause';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import TableRowSkeleton from '../TableRowSkeleton';

import { smsApi } from '../../services/api';
import toastError from '../../errors/toastError';

import { format, parse } from 'date-fns';

const columns = [
  'Nome',
  'Status',
  'Centro de Custo',
  'Total',
  'Higienizados',
  'Disparados',
  'Entregues',
  'Não Entregues',
  '%',
  'Ações',
];

// Mapeia os status internos para as traduções
const statusTranslation = {
  READY: 'Pronta',
  ACTIVE: 'Pronta',
  ANALYZING: 'Em Análise',
  MISSING_FILE: 'Em Análise',
  CANCELLED: 'Cancelada',
  ERROR: 'Erro',
  RUNNING: 'Em Disparo',
  FINISHED: 'Finalizada',
  PAUSED: 'Pausada',
  SCHEDULED: 'Agendada',
};

const statusStyles = {
  READY: { color: '#1976d2', fontWeight: 'bold' }, // Azul
  ACTIVE: { color: '#1976d2', fontWeight: 'bold' }, // Azul
  MISSING_FILE: { color: '#f57c00', fontWeight: 'bold' }, // Laranja
  ANALYZING: { color: '#f57c00', fontWeight: 'bold' }, // Laranja
  ERROR: { color: '#d32f2f', fontWeight: 'bold' }, // Vermelho
  RUNNING: { color: '#388e3c', fontWeight: 'bold' }, // Verde
  FINISHED: { color: '#757575', fontWeight: 'bold' }, // Cinza
  PAUSED: { color: '#388e3c', fontWeight: 'bold' }, // Laranja
  SCHEDULED: { color: '#388e3c', fontWeight: 'bold' }, // Verde
};

const PAGE_SIZE = 40;

// Formata a data para consulta no backend (dd/MM/yyyy)
const formatDateForQuery = date => {
  if (!date) return '';
  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
  return format(parsedDate, 'dd/MM/yyyy');
};

const SmsAppCampaignsTable = ({ startDate, endDate, status, costCenter }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchCampaigns = useCallback(
    async (
      { page, showLoading } = {
        page: 1,
        showLoading: true,
      }
    ) => {
      showLoading && setLoading(true);
      try {
        const params = new URLSearchParams();
        params.append('page', page || 1);
        params.append('page_size', PAGE_SIZE);
        if (startDate)
          params.append('start_date', formatDateForQuery(startDate));
        if (endDate) params.append('end_date', formatDateForQuery(endDate));
        if (status) params.append('status', status);
        if (costCenter !== undefined && costCenter.id)
          params.append('cost_center_id', costCenter.id);

        const { data } = await smsApi.get(`/sms/report/?${params.toString()}`);
        setCampaigns(data.results);
        setTotalPages(Math.ceil(data.count / PAGE_SIZE));
        setCurrentPage(page);
      } catch (error) {
        console.error(error);
        toastError('Erro ao buscar campanhas');
      } finally {
        showLoading && setLoading(false);
      }
    },
    [startDate, endDate, status, costCenter]
  );

  const handleStartCampaign = async campaignId => {
    try {
      await smsApi.post(`/sms/campaigns/${campaignId}/trigger/`);
      fetchCampaigns();
    } catch (error) {
      console.error(error);
      toastError('Erro ao iniciar campanha');
    }
  };

  const handlePauseCampaign = async campaignId => {
    try {
      await smsApi.patch(`/sms/campaigns/${campaignId}/pause/`, {
        success: true,
      });
      fetchCampaigns();
    } catch (error) {
      console.error(error);
      toastError('Erro ao pausar campanha');
    }
  };

  const handleResumeCampaign = async campaignId => {
    try {
      await smsApi.patch(`/sms/campaigns/${campaignId}/resume/`, {
        success: true,
      });
      fetchCampaigns();
    } catch (error) {
      console.error(error);
      toastError('Erro ao resumir campanha');
    }
  };

  useEffect(() => {
    fetchCampaigns();
    const interval = setInterval(() => {
      fetchCampaigns({ showLoading: false });
    }, 30000);
    return () => clearInterval(interval);
  }, [fetchCampaigns]);

  return (
    <Paper variant="outlined" style={{ padding: '16px', overflowX: 'auto' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell key={index} align="center">
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map(campaign => {
            const isDisabled =
              (campaign.status !== 'READY' && campaign.countProcessed === 0) ||
              campaign.status === 'ANALYZING';

            return (
              <TableRow key={campaign.id}>
                <TableCell align="center">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {campaign.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    noWrap
                    display="block"
                  >
                    {campaign.created_at}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {campaign.source === 'API' ? 'API' : 'WEB'}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography style={statusStyles[campaign.status]}>
                    {statusTranslation[campaign.status] || 'Desconhecido'}
                  </Typography>
                  {campaign.accountViolation &&
                    campaign.accountViolation.trim() !== '' && (
                      <Chip
                        label={campaign.accountViolation}
                        size="small"
                        style={{
                          marginTop: 4,
                          backgroundColor: '#d32f2f',
                          color: '#fff',
                          fontSize: '0.65rem',
                          height: 20,
                          padding: '0 6px',
                        }}
                      />
                    )}
                </TableCell>

                <TableCell align="center">{campaign.cost_center}</TableCell>
                <TableCell align="center">{campaign.total}</TableCell>
                <TableCell align="center">{campaign.invalid}</TableCell>
                <TableCell align="center">{campaign.triggered}</TableCell>
                <TableCell align="center">{campaign.delivered}</TableCell>
                <TableCell align="center">{campaign.not_delivered}</TableCell>
                <TableCell align="center">
                  {campaign.triggered > 0
                    ? (campaign.delivered / campaign.triggered) * 100
                    : 0}
                  %
                </TableCell>

                <TableCell align="center">
                  {campaign.status === 'FINISHED' ? (
                    <CheckCircleIcon color="disabled" />
                  ) : campaign.status === 'ERROR' ? (
                    <ErrorOutlineIcon style={{ color: '#d32f2f' }} />
                  ) : campaign.status === 'RUNNING' ? (
                    <IconButton
                      style={{ color: '#388e3c' }}
                      onClick={() => handlePauseCampaign(campaign.id)}
                    >
                      <PauseIcon />
                    </IconButton>
                  ) : campaign.status === 'SCHEDULED' ? (
                    <ScheduleIcon style={{ color: '#388e3c' }} />
                  ) : ['MISSING_FILE', 'ANALYZING'].includes(
                      campaign.status
                    ) ? (
                    <IconButton disabled={true} size="small">
                      <HourglassEmptyIcon></HourglassEmptyIcon>
                    </IconButton>
                  ) : campaign.status === 'PAUSED' ? (
                    <IconButton
                      disabled={isDisabled}
                      size="small"
                      style={{
                        opacity: isDisabled ? 0.5 : 1,
                        color: isDisabled ? '#f57c00' : '#1976d2',
                      }}
                      onClick={() => handleResumeCampaign(campaign.id)}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={isDisabled}
                      size="small"
                      style={{
                        opacity: isDisabled ? 0.5 : 1,
                        color: isDisabled ? '#f57c00' : '#1976d2',
                      }}
                      onClick={() => handleStartCampaign(campaign.id)}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}

          {loading && <TableRowSkeleton columns={columns.length} />}
        </TableBody>
      </Table>

      {/* PAGINAÇÃO */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
          alignItems: 'center',
        }}
      >
        <Button
          disabled={currentPage === 1}
          onClick={() => fetchCampaigns({ page: currentPage - 1 })}
        >
          Anterior
        </Button>
        <Typography>
          Página {currentPage} de {totalPages}
        </Typography>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => fetchCampaigns({ page: currentPage + 1 })}
        >
          Próximo
        </Button>
      </div>
    </Paper>
  );
};

export default SmsAppCampaignsTable;
