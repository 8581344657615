import React, { useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import EmailFrame from './EmailFrame';
import { i18n } from '../../../translate/i18n';
import { emailApi } from '../../../services/api';
import toastError from '../../../errors/toastError';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  multFieldLine: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const EmailViewTemplateModal = ({ open, onClose, templateId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchTemplate = async () => {
      setLoading(true);
      try {
        const { data } = await emailApi.get(`/email/templates/${templateId}/`);
        if (mounted) {
          setTemplate(prev => ({ ...prev, ...data }));
        }
      } catch (err) {
        if (mounted) {
          toastError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    if (open && templateId) {
      fetchTemplate();
    }

    return () => (mounted = false);
  }, [templateId, open]);

  const handleClose = () => {
    onClose();
    setTemplate(null);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
        fullScreen
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h6" component="span">
            {i18n.t('templateModal.title.view')}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            visibility: loading ? 'hidden' : 'visible',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              visibility="visible"
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
            >
              <CircularProgress />
            </Box>
          )}

          <Box>
            <FormControl variant="outlined" margin="dense" disabled>
              <InputLabel id="template-type-label">
                {i18n.t('templateModal.form.type')}
              </InputLabel>
              <Select
                label={i18n.t('templateModal.form.type')}
                placeholder={i18n.t('templateModal.form.type')}
                labelId="template-type-label"
                value="email"
                id="type"
                name="type"
                disabled
              >
                <MenuItem value="email">E-mail</MenuItem>
              </Select>
            </FormControl>

            <TextField
              style={{ marginLeft: 8, width: '250px' }}
              label={i18n.t('templateModal.form.title')}
              value={template?.title || ''}
              autoFocus
              name="title"
              variant="outlined"
              margin="dense"
              inputProps={{ readOnly: true }}
            />
          </Box>

          <Box mb={1}>
            <TextField
              label={i18n.t('templateModal.form.subject')}
              value={template?.subject || ''}
              autoFocus
              name="subject"
              variant="outlined"
              margin="dense"
              fullWidth
              inputProps={{ readOnly: true }}
            />
          </Box>

          <EmailFrame content={template?.content || ''} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            className={classes.btnWrapper}
            onClick={handleClose}
          >
            {i18n.t('templateModal.buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmailViewTemplateModal;
