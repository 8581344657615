import React from "react";
import { Paper } from "@material-ui/core";

const PaperContainer = ({ children, style }) => {
  return (
    <Paper
      style={{
        width: "100%",
        padding: "16px",
        marginBottom: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...style, // Permite sobrescrever estilos externamente
      }}
    >
      {children}
    </Paper>
  );
};

export default PaperContainer;
